import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = "https://www.axelnode-dashboard.com/";

export const fetchAsyncOther_userGet = createAsyncThunk("other_user/get", async (data) => {
    const res = await axios.get(`${apiUrl}api/v1/users_profiles/${data.user_id}/`, {
      headers: {
        Authorization: `Token ${data.token}`,
      },
    });
    return res.data;
});

export const fetchAsyncNode_listGet = createAsyncThunk("node_list/get", async (token) => {
    const res = await axios.get(`${apiUrl}api/v1/nodes/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return res.data;
});

const avatar_infoSlice = createSlice({
    name: 'other_user_info',
    initialState: {
        other_user: {
            id: 0,
            email: "",
            first_name: "",
            last_name: "",
            birth_date: null,
            avatar_url: null,
            position: "",
            company_name: "",
            gender: "NA",
            card: {'number_of_points': 0},
            skills: [],
            experiences: [{"id": 0, "position": "", "ext_company": "", "start_date": "", "end_date": ""}, ],
            educations: [{"id": 0, "institution": "", "degree": "", "field_of_study": "" ,"start_date": "", "end_date": ""}, ],
            languages: [],
            location:"", 
            professions: [],
            fb_link: "",
            ln_link: "",
            resume_link: "",
            cv_link: "",
            profile_visibility: "nobody",
            first_node_count: 0,
            second_node_count: 0,
            date_joined: ""
        },
        node_list: {
            count: 0,
            next: null,
            previous: null,
            results: [],
        },
    },
    reducers: {
        editOther_user_id(state, action) {
            state.other_user.id = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncOther_userGet.fulfilled, (state, action) => {
            state.other_user = action.payload;
        })
        builder.addCase(fetchAsyncOther_userGet.rejected, (state, action) => {
            state.other_user.id = 0;
        })
        builder.addCase(fetchAsyncNode_listGet.fulfilled, (state, action) => {
            state.node_list = action.payload;
        })
    }
})

export const { editOther_user_id } = avatar_infoSlice.actions;
export const selectOther_info = (state) => state.other_user_info.other_user
export const selectNode_list = (state) => state.other_user_info.node_list

export default avatar_infoSlice.reducer;


