import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = "https://www.axelnode-dashboard.com/";
const uuid = localStorage.ticket_uuid;

export const fetchAsyncSubticketGet = createAsyncThunk("sub_ticket/get", async (token) => {
    const res = await axios.get(`${apiUrl}api/v1/sub_tickets/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return res.data;
});

export const fetchAsyncSubticket_afterGet = createAsyncThunk("sub_ticket_after/get", async (data) => {
  const res = await axios.get(`${apiUrl}api/v1/sub_tickets/${data.uuid}`, {
    headers: {
      Authorization: `Token ${data.token}`,
    },
  });
  return res.data;
});

export const fetchAsyncTicketGet = createAsyncThunk("ticket/get", async (data) => {
    const res = await axios.get(`${apiUrl}api/v1/tickets/${data.subticket_id}/info`, {
      headers: {
        Authorization: `Token ${data.token}`,
      },
    });
    return res.data;
});

export const fetchAsyncChainGet = createAsyncThunk("chain/get", async (data) => {
  const res = await axios.get(`${apiUrl}api/v1/sub_tickets/${data.uuid}/refer_users_chain/`, {
    headers: {
      Authorization: `Token ${data.token}`,
    },
  });
  return res.data;
});

export const fetchAsyncCompanyGet = createAsyncThunk("company/get", async (data) => {
  const res = await axios.get(`${apiUrl}api/v1/companies/${data.company_id}`, {
    headers: {
      Authorization: `Token ${data.token}`,
    },
  });
  return res.data;
});

export const fetchAsyncCompanypicGet = createAsyncThunk("companypic/get", async (data) => {
  const res = await axios.get(`${apiUrl}api/v1/companies/${data.company_id}/pictures`, {
    headers: {
      Authorization: `Token ${data.token}`,
    },
  });
  return res.data;
});

export const fetchAsyncApplyPut = createAsyncThunk('apply/put', async (data) => {
  const createData = new FormData();
    createData.append('status', data.subticket_treat.status)
    createData.append('user_for_sharing', data.company_id)
    createData.append('message', data.subticket_treat.message)
  const res = await axios.put(`${apiUrl}api/v1/sub_tickets/${data.subticket_uuid}/`, createData, {
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${data.token}`,
      },
  });
  return res.data;
} 
)

export const fetchAsyncBurnPut = createAsyncThunk('burn/put', async (data) => {
  const createData = new FormData();
    createData.append('status', data.subticket_treat.status)
    createData.append('user_for_sharing', data.company_id)
    createData.append('message', data.subticket_treat.message)
  const res = await axios.put(`${apiUrl}api/v1/sub_tickets/${data.subticket_uuid}/`, createData, {
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${data.token}`,
      },
  });
  return res.data;
} 
)

export const fetchAsyncReferPut = createAsyncThunk('refer/put', async (data) => {
  const createData = new FormData();
    createData.append('status', data.subticket_treat.status)
    createData.append('user_for_sharing', data.subticket_treat.user_for_sharing)
    createData.append('message', data.subticket_treat.message)
  const res = await axios.put(`${apiUrl}api/v1/sub_tickets/${data.subticket_uuid}/`, createData, {
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${data.token}`,
      },
  });
  return res.data;
} 
)

export const fetchAsyncReferFreePut = createAsyncThunk('refer_free/put', async (data) => {
  const createData = new FormData();
    createData.append('status', data.subticket_treat.status)
    createData.append('user_for_sharing', data.company_id)
    createData.append('message', data.subticket_treat.message)
  const res = await axios.put(`${apiUrl}api/v1/sub_tickets/${data.subticket_uuid}/`, createData, {
      headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${data.token}`,
      },
  });
  return res.data;
} 
)


const subticketSlice = createSlice({
    name: 'subticket',
    initialState: {
        subticket_info: {
            count: 0,
            next: null,
            previous: null,
            results: [],
        },
        ticket_info: {
            id: 0,
            title : "",
            company: {id: 0, name: "", owner: 0, status: "", logo_url: null, description: ""},
            status: "",
            type: "",
            publish_date: "",
            expiration_date: "",
            created: "",
            creator: 0,
            initial_depth: 0,
            initial_width: 0,
            skills: [{id: 0, name: ""}],
            experience_requirement: 0,
            compensation: "",
            location: "",
            description: "",
            details: "",
            professions: [""],
            event_date: null,
        },
        chain: {
            user: {first_name: "", last_name: "", avatar_url: null, has_access: true, id: 0, position: "", company_name: "", card: {card_type: 0, number_of_points: 0, is_black: false}},
            parent_user : null,
            parent_parent_user: null,
            child_user: null,
            company: {id: 0, name: "", owner: 0, status: "", logo_url: "", description: ""}
        },
        company_info: {
            id: 0,
            name: "",
            owner: 0,
            status: "",
            logo_url: null,
            description: "",
            office_location: "",
            homepage_link: "",
            company_size: "",
            active_tickets_count: 0,
            created: "",
            modified: "",
        },
        company_pic: {
            id: 0,
            created: '',
            picture: '',
        },
        subticket_treat: {
          status: '',
          user_for_sharing: 0,
          message: '',
        },
        after_ticket: {
          uuid: '',
          user: {first_name: "", last_name: "", avatar_url: null, has_access: true, id: 0},
          ticket: {id: 0, title: '', company: {id: 0, name: '', owner: 0, status: '', logo_url: '', description: ''}, status: '', type: '', publish_date: '', expiration_date: '', created: ''},
          state: '', message: '', remaining_depth: '', can_be_referred: false, direct: false, parent: null, child: null, last_sub_ticket_status: '', code: '', link: '', child_code: '', child_link: '', created: '', modified: '', assigned_date: '',
        },
        subticket_uuid: '',
        external_ticket: {},
        view_toggle: true,
    },
    reducers: {
      editTicket_id(state, action) {
        state.ticket_info.id = action.payload;
      },
      editSubticket_status(state, action) {
        state.subticket_treat.status = action.payload;
      },
      editSubticket_sharing(state, action) {
        state.subticket_treat.user_for_sharing = action.payload;
      },
      editSubticket_message(state, action) {
        state.subticket_treat.message = action.payload;
      },
      editSubticket_uuid(state, action) {
        state.subticket_uuid = action.payload;
      },
      editView_toggle(state, action) {
        state.view_toggle = action.payload;
      },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncSubticketGet.fulfilled, (state, action) => {
            state.subticket_info = action.payload;
        })
        builder.addCase(fetchAsyncSubticket_afterGet.fulfilled, (state, action) => {
          state.after_ticket = action.payload;
      })
        builder.addCase(fetchAsyncTicketGet.fulfilled, (state, action) => {
            state.ticket_info = action.payload;
        })
        builder.addCase(fetchAsyncChainGet.fulfilled, (state, action) => {
          state.chain = action.payload;
      })
        builder.addCase(fetchAsyncCompanyGet.fulfilled, (state, action) => {
          state.company_info = action.payload;
        })
        builder.addCase(fetchAsyncCompanypicGet.fulfilled, (state, action) => {
          state.company_pic = action.payload;
        })
        builder.addCase(fetchAsyncApplyPut.fulfilled, (state, action) => {
          localStorage.setItem('ticket_uuid', action.payload.uuid);
          window.location.href = '/apply_comp';
        })
        builder.addCase(fetchAsyncBurnPut.fulfilled, (state, action) => {
          localStorage.setItem('ticket_uuid', action.payload.uuid);
          window.location.href = '/burn_comp';
        })
        builder.addCase(fetchAsyncReferPut.fulfilled, (state, action) => {
          localStorage.setItem('ticket_uuid', action.payload.uuid);
          window.location.href = '/refer_comp';
        })
        builder.addCase(fetchAsyncReferFreePut.fulfilled, (state, action) => {
          localStorage.setItem('ticket_uuid', action.payload.uuid);
          window.location.href = '/refer_oth_comp';
        })
    }
})

export const { editTicket_id, editCompany_id, editSubticket_status, editSubticket_sharing, editSubticket_message, editSubticket_uuid, editView_toggle } = subticketSlice.actions;
export const selectSubticket = (state) => state.subticket.subticket_info;
export const selectTicket = (state) => state.subticket.ticket_info;
export const selectCompany = (state) => state.subticket.company_info;
export const selectCompanypic = (state) => state.subticket.company_pic;
export const selectSubticket_treat = (state) => state.subticket.subticket_treat;
export const selectSubticket_uuid = (state) => state.subticket.subticket_uuid;
export const selectChain = (state) => state.subticket.chain;
export const selectAfter_ticekt = (state) => state.subticket.after_ticket;
export const selectView_toggle = (state) => state.subticket.view_toggle;


export default subticketSlice.reducer;

