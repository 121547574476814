import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from "react-redux";

import {
    editBirth, 
    selectProfile,
} from "./profile_editSlice";

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: 200,
  },
}));

export default function DatePickers() {
  const dispatch = useDispatch();
  const user_pro = useSelector(selectProfile);
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="date"
        type="date"
        value={user_pro.birth_date}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => dispatch(editBirth(e.target.value))}
      />
    </form>
  );
}