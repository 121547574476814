import React, { useEffect } from 'react';
import { withCookies } from "react-cookie";
import classes from './News.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AlarmIcon from '@material-ui/icons/Alarm';
import Footer from '../footer/Footer';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';

import {
    fetchAsyncNoticeGet,
    selectNotice
} from "./noticesSlice";


const News = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const notice = useSelector(selectNotice);

    const other_id = (number) => {
        let dir = window.location.href.split("/");
        let dir2 = dir[dir.length - number];
        return dir2;
    }


    useEffect(() => {
        dispatch(fetchAsyncNoticeGet({'news_id':  other_id(2), 'token': token}))
    }, [dispatch]);

    return (
        <div className={classes.body}>
            <div>
                <div className={classes.head}>
                    <Link to='/notices/'>
                        <button className={classes.left}><ArrowBackIosIcon /></button>
                    </Link>
                    <span className={classes.center}>News</span>
                </div>
            </div>
            <div>
                <div className={classes.top}>{notice.title}</div>
                <div className={classes.time}>
                    <AlarmIcon/>
                    <div className={classes.day}>{notice.created.slice(0, 10)}</div>
                </div>
            </div>
            <div className={classes.str}>{notice.description}</div>
            <Footer />
        </div>
    )
}

export default withCookies(News)
