import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = "https://www.axelnode-dashboard.com/";

export const fetchAsyncUserInvitationPut = createAsyncThunk('invitation/put', async (data) => {
    const res = await axios.put(`${apiUrl}api/v1/sub_tickets/${data.uuid}/assign/`, data.token,{
        headers: {
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)

export const fetchAsyncExt_sub_tickeGet = createAsyncThunk('ext_sub_ticke/get', async (data) => {
    const res = await axios.get(`${apiUrl}api/v1/ext_sub_ticket/${data.code}/`, {
        headers: {
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)


const invitationSlice = createSlice({
    name: 'invitation',
    initialState: {
        uuid: '',
        error: '',
    },
    reducers: {
        editUUID(state, action) {
            state.uuid = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncUserInvitationPut.fulfilled, (state, action) => {
            window.location.href = '/open_ticket';
            state.error = '';
        });
        builder.addCase(fetchAsyncUserInvitationPut.rejected, (state, action) => {
            state.error = 'This code is not valid';
        });
        builder.addCase(fetchAsyncExt_sub_tickeGet.fulfilled, (state, action) => {
            state.uuid = action.payload.uuid;
        });
    },
})

export const { editUUID } = invitationSlice.actions;
export const selectUUID = (state) => state.invitation.uuid;
export const selectError = (state) => state.invitation.error;

export default invitationSlice.reducer;


