import classes from './Require.module.css'
import React from 'react'
import Btnmark from './Btnmark'

const Require = ({ticket}) => {
    return (
        <div>
            <div className={classes.box}>
                <div className={classes.top}>Requirements</div>
                <ul className={classes.list}>
                    {ticket.professions.map((pro) => (
                        <li>{pro}</li>
                    ))}
                    <li>{ticket.experience_requirement} year experience in the industry</li>
                </ul>
                <div className={classes.flex_box}>
                    {ticket.skills.map((skill) => (
                        <Btnmark skill={skill}/>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Require

