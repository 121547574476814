import React, {useEffect } from 'react'
import { useSelector, useDispatch } from "react-redux";

import {
    fetchAsyncActivatePut,
} from "./activateSlice";

const Activate = () => {
    const activate_token = window.location.href.slice(-100)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchAsyncActivatePut(activate_token))
    }, [dispatch]);
    
    return (
        <div>
            <div>account activate</div>
        </div>
    )
}

export default Activate
