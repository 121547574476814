import React, { useState, useEffect } from 'react';
import { withCookies } from "react-cookie";
import classes from './Notices.module.css';
import Footer from '../footer/Footer';
import Avatar from '@material-ui/core/Avatar';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';

import {
    fetchAsyncNoticeListGet,
    fetchAsyncNoticeSystemListGet,
    selectNoticeSystem_list,
    selectNotice_list,
} from "./noticesSlice";

const Notices = (props) => {
    const [toggle, setToggle] = useState(true);
    const token = props.cookies.get("current-token");
    const dispatch = useDispatch();
    const notice_list = useSelector(selectNotice_list);
    const notice_system_list = useSelector(selectNoticeSystem_list);

    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);

    useEffect(() => {
        dispatch(fetchAsyncNoticeListGet(token))
        dispatch(fetchAsyncNoticeSystemListGet(token))
    }, [dispatch]);

    return (
        <div className={classes.body}>
            <div className={classes.header}>
                <button className={toggle ? classes.inhead_under : classes.inhead} onClick={() => setToggle(true)}>NOTICES</button>
                <button className={toggle ? classes.inhead : classes.inhead_under} onClick={() => setToggle(false)}>NEWS</button>
            </div>
            { !(notice_list.length === 0) && 
                <div>
                    { toggle ?
                        <div>
                            {notice_list.results.map((noti) => (
                                
                            <div key={noti.id}>
                                <Link to={`/news/${noti.id}/`} className={classes.node} style={{ textDecoration: 'none', color: '#fff' }}>
                                    <Avatar className={classes.avatar} src={noti.creator.avatar_url}/>
                                    <div>
                                        <div className={classes.note}>{noti.description.slice(0, 55)}</div>
                                        <div  className={classes.time}>{noti.created.slice(0, 10)}</div>
                                    </div>
                                </Link>
                            </div>
                            ))}
                        </div>
                        :
                        <div>   
                            <div>   
                           
                           </div>
                        </div>
                    }
                </div>
            }
            { !(notice_system_list.length === 0) && 
                <div>
                    { toggle ?
                        <div>
                            {notice_system_list.results.map((noti_system) => (
                            <div key={noti_system.id} className={classes.node}>
                                <Avatar className={classes.avatar} url={noti_system.related_user_avatar_url}/>
                                <div>
                                    <div className={classes.note}>{noti_system.message}</div>
                                    <div  className={classes.time}>{noti_system.created.slice(0, 10)}</div>
                                </div>
                            </div>
                            ))}
                        </div>
                        :
                        <div>   
                           
                        </div>
                    }
                </div>
            }
            { !toggle &&
                <div style={{textAlign: 'center', marginTop: '200px', color: '#929292'}}>   
                        No News
                </div>
            }
           <Footer />
        </div>
    )
}

export default withCookies(Notices)
