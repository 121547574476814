import React, { useEffect } from 'react';
import { withCookies } from 'react-cookie';
import classes from './How3.module.css';
import Icon from './Group 148.png';
import Icon2 from './Group 149.png';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom'

const How3 = (props) => {
    const token = props.cookies.get("current-token");

    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);

    return (
        <div className={classes.header}>
            <div className={classes.head}>
                <Link to='/how2/'>
                    <button className={classes.left}><ArrowBackIosIcon /></button>
                </Link>
                <span className={classes.center}>How to use</span>
            </div>
            <div>
                <hi className={classes.top}>GET TICKET</hi>
            </div>
            <div>
                <img className={classes.pic} src={Icon} />
            </div>
            <div>
                <h2 className={classes.str}>When you get a ticket from your network, that ticket gives you or your friend a great job opportunity.</h2>
            </div>
            <div>
                <img className={classes.time} src={Icon2} />
            </div>
            <Link to='/how4/'>
                <button
                    variant="contained"
                    className={classes.next_btn}
                    color="primary"
                >
                    Next
                </button>
            </Link>


        </div>
    )
}

export default withCookies(How3)
