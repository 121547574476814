import React, { useState } from 'react';
import Icon from './AXN_Logo_Horizontal.png';
import classes from './ForgotPas.module.css';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';

import {
    editEmail,
    fetchAsyncEmailPut,
    selectEmail,
  } from "./forgotpasSlice";


const ForgotPas = () => {
    const dispatch = useDispatch();
    const email = useSelector(selectEmail);
    const [success_send, setSuccess_send] = useState(false)
    const [failure_send, setFailure_send] = useState(false)

    const successClose = () => {
        setSuccess_send(false);
    };
    const failureClose = () => {
        setFailure_send(false);
    };

    const send_email = async () => {
        const result = await dispatch(fetchAsyncEmailPut(email))

        if (fetchAsyncEmailPut.fulfilled.match(result)) {
            setSuccess_send(true);
        } else {
            setFailure_send(true);
        }
    }


    return (
        <div className={classes.header}>
            <div>
                <img className={classes.img} src={Icon}/>
            </div>
            <div>
                <div>
                    <div className={classes.form}>
                        <input className={classes.input}
                            type="email"
                            name="Email"
                            placeholder="Email"
                        
                            onChange={(e) => dispatch(editEmail(e.target.value))}
                        />
                    </div>
                </div>
                <hr className={classes.hr}/>
                <div>
                    <button
                        variant="contained"
                        className={classes.send_btn}
                        color="primary"
                        onClick={send_email}
                    >
                        Send Email
                    </button>
                    <Dialog style={{textAlign: 'center'}}
                        open={success_send}
                        onClose={successClose}
                        aria-labelledby="logout-dialog-title"
                        aria-describedby="logout-dialog-description"
                    >
                        <DialogTitle id="logout-dialog-title">{"Success"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="logout-dialog-description">
                            Your password has been sent. Please check your email
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={successClose} color="primary">
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog style={{textAlign: 'center'}}
                        open={failure_send}
                        onClose={failureClose}
                        aria-labelledby="logout-dialog-title"
                        aria-describedby="logout-dialog-description"
                    >
                        <DialogTitle id="logout-dialog-title">{"Error"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="logout-dialog-description">
                            Something was wrong, try again.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={failureClose} color="primary">
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <br/>
                    <button className={classes.cancel_btn}>
                        <Link to='/createOne/' style={{ textDecoration: 'none', color: '#424242' }}>Cancel</Link>
                    </button>
                </div>
                
            </div>
            
            
            
        </div>
    )
}

export default ForgotPas
