import classes from './Ticket.module.css';
import React from 'react';
import Avatar from '@material-ui/core/Avatar';

const Ticket = ({key, sub}) => {
    return (
        <div className={classes.box}>
            <div className={classes.avatar}>
                <Avatar src={sub.ticket.company.logo_url}/>
            </div>
            <div className={classes.ticket}>
            <div className={classes.incard}>
                <div>
                    <div className={classes.pos}>
                        <div className={classes.str1}>{sub.ticket.title}</div>
                        <div className={classes.str2}>{sub.ticket.company.name}</div>
                        <div className={classes.inincard}>
                            <div className={classes.str2}>{sub.ticket.type}</div>
                            <div className={classes.str3}>{sub.ticket.publish_date.slice(0, 10)}</div>
                        </div>
                    </div>
                    
                </div>

                <div className={classes.rotate}>
                    <h3  className={classes.ver_str}>expires on {sub.ticket.expiration_date.slice(0, 10)}</h3>
                </div>
            </div>
                
               
                
            </div>
        </div>
    )
}

export default Ticket
