import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = "https://www.axelnode-dashboard.com/";
const token = localStorage.localJWT;

export const fetchAsyncRegister = createAsyncThunk('login/register', async (auth) => {
    const res = await axios.post(`${apiUrl}api/v1/users/create_user/`, auth, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
} 
)

const RegisterSlice = createSlice({
    name: 'register',
    initialState: {
        authen: {
            email: "",
            password: "",
            first_name: "",
            last_name: "",
        },
    },
    reducers: {
        editEmail(state, action) {
            state.authen.email = action.payload;
        },
        editPassword(state, action) {
            state.authen.password = action.payload;
        },
        editFirst(state, action) {
            state.authen.first_name = action.payload;
        },
        editLast(state, action) {
            state.authen.last_name = action.payload;
        },
    },
})

export const {editEmail, editPassword, editFirst, editLast, editSuccess, editFailure } = RegisterSlice.actions;
export const selectAuthen = (state) => state.register.authen;

export default RegisterSlice.reducer;


