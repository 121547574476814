import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = "https://www.axelnode-dashboard.com/";

export const fetchAsyncPasswordPut = createAsyncThunk('password/put', async (data) => {
    const createData = new FormData();
    createData.append('old_password', data.pas.old_pas)
    createData.append('password', data.pas.new_pas)
    const res = await axios.put(`${apiUrl}api/v1/users/change_password/`, createData, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)

const changeSlice = createSlice({
    name: 'change_password',
    initialState: {
        pas_info: {
            old_pas: '',
            new_pas: '',
        },
        error: '',
    },
    reducers: {
        editOld_pas(state, action) {
            state.pas_info.old_pas = action.payload;
        },
        editNew_pas(state, action) {
            state.pas_info.new_pas = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncPasswordPut.fulfilled, (state, action) => {
            window.location.href = '/setting';
            state.error = ''
        })
        builder.addCase(fetchAsyncPasswordPut.rejected, (state, action) => {
            state.error = action.error.message
        })
    },
})

export const { editOld_pas, editNew_pas } = changeSlice.actions;
export const selectNew_pas = (state) => state.change_password.pas_info;
export const selectError = (state) => state.change_password.error;

export default changeSlice.reducer;


