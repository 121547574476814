import React from 'react';
import classes from './Footer.module.css';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { Link } from 'react-router-dom';
import Icon from './unnamed.png'
import ticket from './ticket.png'

const Footer = () => {
    return (
        <div className={classes.footer}>
            <footer className={classes.icon}>
                <Link to='/open_ticket/' style={{ textDecoration: 'none', color: '#424242' }}>
                <img src={ticket} style={{width: '50px'}}/>
                </Link>
                <Link to='/node_list/'style={{ textDecoration: 'none', color: '#424242' }} >
                    <img src={Icon} style={{width: '30px'}}/>
                </Link>
                <Link to='/notices/' style={{ textDecoration: 'none', color: '#424242' }}>
                    <NotificationsNoneIcon/>
                </Link>
                <Link to='/profile/' style={{ textDecoration: 'none', color: '#424242' }}>
                    <PermIdentityIcon/>
                </Link>
            </footer>
        </div>
    )
}

export default Footer
