import React, {useEffect} from 'react';
import { withCookies } from "react-cookie";
import Icon from './AXN_Logo_Horizontal.png';
import classes from './Authentication.module.css';
import { Link } from 'react-router-dom';


const Authentication = () => {
    (async () => {
        const pair = await crypto.subtle.generateKey(
          {
            name: 'RSASSA-PKCS1-v1_5',
            modulusLength: 2048,
            publicExponent: new Uint8Array([1, 0, 1]),
            hash: 'SHA-256'
          },
          true,
          ['sign', 'verify']
        );
         
        console.log('=== private key ===');
        console.log(JSON.stringify(await crypto.subtle.exportKey('jwk', pair.privateKey), null, '  '));
         
        console.log('=== public key ===');
        console.log(JSON.stringify(await crypto.subtle.exportKey('jwk', pair.publicKey), null, '  ')); 
      })();

    return (
        <div className={classes.header}>
            <img className={classes.img} src={Icon}/>
            
            
            <div className={classes.note}>
                By tapping “login”, you agree to our<br/>
                Terms of services and Privacy Policy
            </div>
            <button　className={classes.create_btn}>
                <Link to='/createOne/' style={{ textDecoration: 'none', color: '#424242' }}>Create an Axelnode account</Link>
            </button>
            <div>
                <button className={classes.facebook_btn}>Login with Facebook</button>
                <button className={classes.linkdin_btn}>Login with LinkedIn</button>
                <button className={classes.email_btn}>
                    <Link to='/login/'  style={{ textDecoration: 'none', color: '#424242' }}>Login with Email</Link>
                </button>
            </div>
            <button className={classes.toggle}>Trouble logging in?</button>
        </div>
    )
}

export default withCookies(Authentication)
