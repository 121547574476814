import React, {useEffect} from 'react';
import { withCookies } from "react-cookie";
import classes from './Refer_ax.module.css';
import Avatar from '@material-ui/core/Avatar';
import { useSelector, useDispatch } from "react-redux";

import {
    selectTicket,
    fetchAsyncReferPut,
    selectSubticket_treat,
    selectSubticket_uuid,
    editSubticket_sharing,
    editSubticket_message,
    selectChain,
} from "../open_ticket/open_ticketSlice";

import {
    selectNode_list,
    fetchAsyncNode_listGet,
} from "../node_list/node_profileSlice";

const Refer_ax = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const subticket_treat = useSelector(selectSubticket_treat);
    const ticket = useSelector(selectTicket);
    const subticket_uuid = useSelector(selectSubticket_uuid);
    const chain = useSelector(selectChain);
    const node_list = useSelector(selectNode_list);

    const first = node_list.results.filter((output) => {
        return output.contact_type == "first";
    })

    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);


    useEffect(async() => {
        if (ticket.id === 0) {
            window.location.href = '/open_ticket';
        }

        dispatch(fetchAsyncNode_listGet(token))
    }, [dispatch]);

    const refer = async () => {
        const res = await dispatch(fetchAsyncReferPut({'subticket_treat': subticket_treat, 'subticket_uuid': subticket_uuid, 'token': token}))
        props.cookies.set("after_ticek_id", res.payload.uuid, {maxAge: 3600, path: '/'});
    }
    
    return (
        <div>
            <div>
                <div className={classes.head}>
                    <span className={classes.center}>Business Development</span>
                </div>
            </div>
            <div className={classes.top}>Choose friend and add your message</div>
            <div className={classes.set}>
                {chain.parent_user != null &&
                    <div className={classes.set}>
                        <div className={classes.first}></div>
                        <Avatar src={chain.parent_user.avatar_url}/>
                    </div>
                }
                <div className={classes.set}>
                    <div className={classes.second}></div>
                    <Avatar src={chain.user.avatar_url}/>
                </div>
                <div className={classes.set}>
                    <div className={classes.second}></div>
                    <Avatar />
                </div>
            </div>
            <div className={classes.nodes}>
                {first.map((fir) => ( 
                    subticket_treat.user_for_sharing == fir.contact.id ? 
                    <div key={fir.id} className={classes.wrap}>
                        <Avatar src={fir.contact.avatar_url} onClick={() => dispatch(editSubticket_sharing(fir.contact.id))} className={classes.avatar}/>
                        <div className={classes.avatar_name1}>{fir.contact.first_name} {fir.contact.last_name}</div>   
                    </div>
                    : 
                    <div key={fir.id} className={classes.wrap}>
                        <Avatar src={fir.contact.avatar_url} onClick={() => dispatch(editSubticket_sharing(fir.contact.id))} className={classes.avatar}/>
                        <div className={classes.avatar_name2}>{fir.contact.first_name} {fir.contact.last_name}</div>   
                    </div>
                    
                ))}
            </div>

            <textarea 
             className={classes.form} 
             placeholder='Message for refer' 
             name="refer" cols="40" rows="5"
             value={subticket_treat.message}
             onChange={(e) => dispatch(editSubticket_message(e.target.value))} 
             maxLength='1000'/>
            <button className={classes.str1} onClick={() => dispatch(editSubticket_message('I sent a special job offer because I think that you are the best person who has some skills on this thicket in my network. Please check it out!'))}>Apply a template</button>

            <div className={classes.footer}>
                <div className={classes.value}>Reward: 200 AXP</div>
                <button className={classes.app} onClick={refer}>Refer</button>
            </div>
            
            
            

        </div>
    )
}

export default withCookies(Refer_ax)
