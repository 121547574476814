import React, {useEffect} from 'react';
import { withCookies } from "react-cookie";
import Icon from './AXN_Logo_Horizontal.png';
import classes from './CreateOne.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import {
    editFirst,
    editLast,
    selectAuthen,
  } from "../createtwo/createtwoSlice";

const CreateOne = (props) => {
    const dispatch = useDispatch();
    const authen = useSelector(selectAuthen);
    const btnDisabler = authen.first_name === "" || authen.last_name === "";

    return (
        <div className={classes.head}>
            <div>
                <img className={classes.img} src={Icon}/>
            </div>
            <div>
                <div className={classes.form}>
                    <input className={classes.input}
                        type="text"
                        name="First_Name"
                        placeholder="First Name"
                        onChange={(e) => dispatch(editFirst(e.target.value))}
                        required
                    />
                </div>
                <hr className={classes.hr}/>
                <div className={classes.form}>
                    <input className={classes.input}
                        type="text"
                        name="Last_Name"
                        placeholder="Last Name"
                        onChange={(e) => dispatch(editLast(e.target.value))}
                        required
                    />
                </div>
                <hr className={classes.hr}/>

                <div className={classes.note}>
                    {btnDisabler && 
                        <h2 className={classes.note1}>* Please fill all required fields</h2>
                    }
                </div>

                <div className={classes.box}>
                    <div className={classes.minibox}>
                        <ArrowBackIosIcon />
                        <Link to='/' style={{ textDecoration: 'none', color: '#424242'  }}>Back</Link>
                    </div>
                    <div className={classes.minibox}>
                        { btnDisabler ? (
                            <Link to='/createTwo/' style={{ textDecoration: 'none', color: '#424242', pointerEvents: 'none' }} >Next</Link> 
                        ) : (
                            <Link to='/createTwo/' style={{ textDecoration: 'none', color: '#424242' }} >Next</Link>
                        )}
                        <ArrowForwardIosIcon />
                    </div>   
                </div>

               
                
            </div>
            
            
            
        </div>
    )
}

export default withCookies(CreateOne)
