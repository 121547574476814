import React, { useEffect } from 'react';
import { withCookies } from 'react-cookie';
import classes from './Education.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import {
    editEdu_institution, 
    editEdu_dgree, 
    editEdu_field, 
    editEdu_start, 
    editEdu_end,
    selectEducation,
    selectProfile,
    fetchAsyncEducationGet,
    fetchAsyncEducationDelete,
    fetchAsyncEducationPut,
    fetchAsyncUserProGet
} from "../profile/profile_editSlice";

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2),
      width: 150,
    },
}));

const Education = (props) => {
    const [checked, setChecked] = React.useState(true);
    const token = props.cookies.get("current-token");
    const dispatch = useDispatch();
    const user_pro = useSelector(selectProfile);
    const education = useSelector(selectEducation);
    const styles = useStyles();

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
      };
    const handleClose = () => {
    setOpen(false);
    };

    useEffect(() => {
        dispatch(fetchAsyncEducationGet({'user_pro': user_pro, 'edu': education, 'token': token}))
    }, [dispatch]);

    const delete_edu = async () => {
        await dispatch(fetchAsyncEducationDelete({'user_pro': user_pro, 'edu': education, 'token': token}))
    }

    const update = async () => {
        await dispatch(fetchAsyncEducationPut({'user_pro': user_pro, 'edu': education, 'token': token}))
    }

    return (
        <div>
            <div className={classes.header}>
                <Link to='/profile_edit/'>
                    <button className={classes.left}><ArrowBackIosIcon /></button>
                </Link>
                <span className={classes.center}>Education Edit</span>
            </div>

            <div className={classes.head}>
                <div>School/University</div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="text"
                        name="School"
                        size="49"
                        placeholder='Harvard, Stanford, etc.'
                        value={education.institution}
                        onChange={(e) => dispatch(editEdu_institution(e.target.value))}
                    />
                </div>
            </div>

            <div className={classes.head}>
                <div>Degree</div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="text"
                        name="Degree"
                        size="49"
                        placeholder='Bachelor, Master, etc.'
                        value={education.degree}
                        onChange={(e) => dispatch(editEdu_dgree(e.target.value))}
                    />
                </div>
            </div>

            <div className={classes.head}>
                <div>Field of study</div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="text"
                        name="Field"
                        size="49"
                        placeholder='engineering, chemistry, etc.'
                        value={education.field_of_study}
                        onChange={(e) => dispatch(editEdu_field(e.target.value))}
                    />
                </div>
            </div>

            <div className={classes.parent}>
                <div className={classes.child1}>
                    <div>Period</div>
                    <div>Start</div>
                    <form className={classes.container} noValidate>
                        <TextField
                            id="date"
                            type="date"
                            defaultValue={education.start_date}
                            className={styles.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            onChange={(e) => dispatch(editEdu_start(e.target.value))}
                            value={education.start_date}
                        />
                    </form>
                </div>
                <div className={classes.child2}>
                    <div className={classes.space}></div>
                    <div>End(expected)</div>
                    <div>
                        <form className={classes.container} noValidate>
                            <TextField
                                id="date"
                                type="date"
                                defaultValue={education.end_date}
                                className={styles.textField}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={(e) => dispatch(editEdu_end(e.target.value))}
                                value={education.end_date}
                            />
                        </form>
                    </div>
                </div>
            </div>

            <div>
                <div className={classes.btnpos}>
                    <button
                        variant="contained"
                        className={classes.tag1}
                        onClick={update}
                    >
                        Update
                    </button>
                </div>
                <div className={classes.btnpos}>
                    <button
                        variant="contained"
                        className={classes.tag2}
                        onClick={handleClickOpen}
                    >
                        Delete
                    </button>
                    <Dialog style={{textAlign: 'center'}}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="logout-dialog-title"
                        aria-describedby="logout-dialog-description"
                    >
                        <DialogTitle id="logout-dialog-title">{""}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="logout-dialog-description">
                            Are you sure you want to log out?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleClose} color="primary" autoFocus onClick={delete_edu}>
                            Delete
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default withCookies(Education)
