import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = "https://www.axelnode-dashboard.com/";

export const fetchAsyncNotificationGet = createAsyncThunk("notification/get", async (data) => {
    const res = await axios.get(`${apiUrl}api/v1/notifications/user_notifications_settings/${data.user_id}/`, {
      headers: {
        Authorization: `Token ${data.token}`,
      },
    });
    return res.data;
});

export const fetchAsyncNotificationPut = createAsyncThunk('notification/put', async (data) => {
    const res = await axios.put(`${apiUrl}api/v1/notifications/user_notifications_settings/${data.user_id}/`, data.note, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)


const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        setting: {
            ticket_actions_push: true,
            ticket_actions_email: true,
            axp_updates_push: true,
            axp_updates_email: true,
            reward_updates_push: true,
            reward_updates_email: true,
        },
    },
    reducers: {
        editTicket_push(state, action) {
            state.setting.ticket_actions_push = action.payload;
        },
        editTicket_email(state, action) {
            state.setting.ticket_actions_email = action.payload;
        },
        editAxp_push(state, action) {
            state.setting.axp_updates_push = action.payload;
        },
        editAxp_email(state, action) {
            state.setting.axp_updates_email = action.payload;
        },
        editReward_push(state, action) {
            state.setting.reward_updates_push = action.payload;
        },
        editReward_email(state, action) {
            state.setting.reward_updates_email = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncNotificationGet.fulfilled, (state, action) => {
            state.setting = action.payload;
        })
    }
})

export const { editTicket_push, editTicket_email, editAxp_push, editAxp_email, editReward_push, editReward_email } = notificationSlice.actions;
export const selectNotification = (state) => state.notification.setting;


export default notificationSlice.reducer;


