import React, { useEffect, useState } from 'react';
import { withCookies } from "react-cookie";
import Avatar from '@material-ui/core/Avatar';
import classes from './Profile_edit.module.css';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Gender from './Gender.js';
import Date from './Date.js';
import Who from './Who.js';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import AddIcon from '@material-ui/icons/Add';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SimpleReactFileUpload from './Upload_avatar';
import Button from '@material-ui/core/Button';

import {
    editFirst, 
    editLast, 
    editEmail, 
    editLocation, 
    editProfessions, 
    editFB, 
    editLN, 
    editResume, 
    editCV,
    editEdu_id,
    editExp_id,
    selectProfile,
    selectSkill,
    selectLang,
    selectLang_list,
    fetchAsyncUserProGet,
    fetchAsyncUserProPut,
    fetchAsyncSkillPost,
    fetchAsyncSkillDelete,
    fetchAsyncLanguageGet,
    fetchAsyncLanguagePost,
    fetchAsyncLanguageDelete,
    editSkill_id, 
    editSkill_name,
    editLang_id, 
    editLang_name,
} from "./profile_editSlice";


const Profile_edit = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const user_pro = useSelector(selectProfile);
    const skill = useSelector(selectSkill);
    const language = useSelector(selectLang);
    const [open_avatar, setOpen_avatar] = useState(false)
    const [open_skill, setOpen_skill] = useState(false)
    const [open_lang, setOpen_lang] = useState(false)
    const [inputValue, setInputValue] = React.useState('');
    const language_list = useSelector(selectLang_list);

    const [profession1, setProfession1] = useState(user_pro.professions[0])
    const [profession2, setProfession2] = useState(user_pro.professions[1])
    const [profession3, setProfession3] = useState(user_pro.professions[2])

    useEffect(() => {
        dispatch(editProfessions([profession1, profession2, profession3]))
    }, [profession1, profession2, profession3]);

    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
        setProfession1(user_pro.professions[0])
        setProfession2(user_pro.professions[1])
        setProfession3(user_pro.professions[2])
    }, [token, user_pro.id]);

    var option = [];
    var id_language = {}
    for (var a in language_list['results']) {
        option.push(language_list['results'][a]['name'])
        id_language[language_list['results'][a]['name']] = language_list['results'][a]['id']
    }
    const options = option
    const [value, setValue] = React.useState(options[0]);

    const avatar_close = () => {
        setOpen_avatar(false)
    }

    const skill_close = () => {
        setOpen_skill(false)
    }

    const lang_close = () => {
        setOpen_lang(false)
    }

    useEffect(() => {
        dispatch(fetchAsyncUserProGet(token))
        dispatch(fetchAsyncLanguageGet(token))
    }, [dispatch]);


    const edit_profile = async() => {
        const result = await dispatch(fetchAsyncUserProPut({'user_pro': user_pro, 'token': token}))

        if (fetchAsyncUserProPut.fulfilled.match(result)) {
            window.location.href = '/profile';
        }
        else {
        }
    }

    const edit_profile_education = async() => {
        const result = await dispatch(fetchAsyncUserProPut({'user_pro': user_pro, 'token': token}))

        if (fetchAsyncUserProPut.fulfilled.match(result)) {
            window.location.href = '/education';
        }
        else {
        }
    }

    const edit_profile_experience = async() => {
        const result = await dispatch(fetchAsyncUserProPut({'user_pro': user_pro, 'token': token}))

        if (fetchAsyncUserProPut.fulfilled.match(result)) {
            window.location.href = '/experience';
        }
        else {
        }
    }

    const skill_post = async () => {
        await dispatch(fetchAsyncUserProPut({'user_pro': user_pro, 'token': token}))
        await dispatch(fetchAsyncSkillPost({'user_pro': user_pro, 'skill': skill, 'token': token}))
        await dispatch(fetchAsyncUserProGet(token))
    }

    const skill_delete = async() => {
        await dispatch(fetchAsyncSkillDelete({'user_pro': user_pro, 'skill': skill, 'token': token}))
        setOpen_skill(false)
        await dispatch(fetchAsyncUserProGet(token))
    }

    const language_post = async () => {
        await dispatch(fetchAsyncLanguagePost({'user_pro': user_pro, 'lang': language, 'token': token}))
        await dispatch(fetchAsyncUserProPut({'user_pro': user_pro, 'token': token}))
        await dispatch(fetchAsyncUserProGet(token))
    }

    const language_delete = async() => {
        await dispatch(fetchAsyncLanguageDelete({'user_pro': user_pro, 'lang': language, 'token': token}))
        setOpen_lang(false)
        await dispatch(fetchAsyncUserProGet(token))
    }

    
    
    return (
        <div>
            <div className={classes.header}>
                <button className={classes.head_btn1}>
                    <Link to='/profile/' style={{ textDecoration: 'none', color: '#fff' }}>Cancel</Link>
                </button>
                <button className={classes.head_btn2} onClick={edit_profile}>SAVE</button>
            </div>
            <div className={classes.avatar_head}>
                <Avatar className={classes.avatar} src={user_pro.avatar_url} onClick={() => setOpen_avatar(true)}/>
                <Dialog style={{textAlign: 'center'}}
                        open={open_avatar}
                        onClose={avatar_close}
                        aria-labelledby="logout-dialog-title"
                        aria-describedby="logout-dialog-description"
                    >
                        <DialogTitle id="logout-dialog-title">{""}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="logout-dialog-description">
                            Please select your picture and upload.
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <SimpleReactFileUpload user_id={user_pro.id} avatar_close={avatar_close}/>
                        </DialogActions>
                    </Dialog>   
            </div>
            <div className={classes.parent}>
                <div className={classes.child1}>
                    <div>First name</div>
                    <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Frist_name"
                            style={{width:'160px'}}
                            value={user_pro.first_name}
                            onChange={(e) => dispatch(editFirst(e.target.value))}
                        />
                    </div>
                </div>
                <div className={classes.child2}>
                    <div>Surname</div>
                    <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Frist_name"
                            style={{width:'160px'}}
                            value={user_pro.last_name}
                            onChange={(e) => dispatch(editLast(e.target.value))}
                        />
                    </div>
                </div>
            </div>


            <div>
                <div className={classes.head}>
                    <div>Email</div>
                    <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Email"
                            size="49"
                            value={user_pro.email}
                            onChange={(e) => dispatch(editEmail(e.target.value))}
                        />
                    </div>
                </div>
                <div className={classes.head}>
                    <div>Location</div>
                    <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Location"
                            size="49"
                            value={user_pro.location}
                            onChange={(e) => dispatch(editLocation(e.target.value))}
                        />
                    </div>
                </div>
                <div className={classes.head}>
                    <div>Professions</div>
                    <div style={{fontSize: '10px', color: 'red'}}>three professions</div>
                    <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Professions"
                            size="49"
                            placeholder={user_pro.professions[0]}
                            onChange={(e) => setProfession1(e.target.value)}
                        />
                    </div>
                    <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Professions"
                            size="49"
                            placeholder={user_pro.professions[1]}
                            onChange={(e) => setProfession2(e.target.value)}
                        />
                    </div>
                    <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Professions"
                            size="49"
                            placeholder={user_pro.professions[2]}
                            onChange={(e) => setProfession3(e.target.value)}
                        />
                    </div>
                </div>

                <div className={classes.head}>
                    <div>Language</div>
                    <Autocomplete
                        value={value}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                        dispatch(editLang_name(id_language[newInputValue]))
                        }}
                        id="controllable-states-demo"
                        options={options}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} />}
                    />   
                    <AddIcon onClick={language_post}/>
                    {user_pro.languages.map((each_language) => (
                        <div key={each_language.id} className={classes.skill_head}>
                        <div onClick={()=> setOpen_lang(true)} className={classes.skill}>
                            {each_language.language}<span 
                                                    onClick={() => {
                                                        dispatch(editLang_id(each_language.id));
                                                        dispatch(fetchAsyncUserProPut({'user_pro': user_pro, 'token': token}));
                                                    }
                                                    }
                                                    >   
                                                    X
                                                    </span>
                        </div>
                        </div>
                    ))}
                    <Dialog style={{textAlign: 'center'}}
                        open={open_lang}
                        onClose={lang_close}
                        aria-labelledby="logout-dialog-title"
                        aria-describedby="logout-dialog-description"
                    >
                        <DialogTitle id="logout-dialog-title">{""}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="logout-dialog-description">
                            Are you sure you want to delete?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={lang_close} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={language_delete} color="primary" autoFocus>
                            Delete
                        </Button>
                        </DialogActions>
                    </Dialog>   
                </div>
            </div>
            <div className={classes.head}>
                <div className={classes.private}>Private information</div>
                <div className={classes.parent}>
                    <div className={classes.child1}>
                        <div>Gender</div>
                        <div className={classes.gender}>
                            <Gender />
                        </div>
                    </div>
                    <div className={classes.child2}>
                        <div>Date of birth</div>
                        <div>
                            <Date />
                        </div>
                    </div>
                </div>
                <div>
                    <div className={classes.add_exp}>
                        <div>Education</div> 
                        <Link to='/education/'>
                            <button className={classes.exp_btn} onClick={edit_profile_education}>+ add educations</button>
                        </Link>
                    </div>
                    { !(user_pro.educations.length === 0) && 
                    <div className={classes.box}>
                        <div>
                            <div className={classes.experience1}>{user_pro.educations[0]['institution']}</div>
                            <div className={classes.experience2}>{user_pro.educations[0]['field_of_study']}/{user_pro.educations[0]['degree']}</div>
                            <div className={classes.experience2}>{user_pro.educations[0]['start_date']}-{user_pro.educations[0]['end_date']}</div>
                            <Link to='/education_edit/' style={{ textDecoration: 'none', color: '#424242' }}>
                                <EditIcon 
                                onClick={() => {
                                    dispatch(editEdu_id(user_pro.educations[0]['id']));
                                    dispatch(fetchAsyncUserProPut({'user_pro': user_pro, 'token': token}));
                                }
                                }
                                />
                            </Link>
                          
                        </div>
                        {user_pro.educations.slice(1).map((each_edu) => (
                            <div>
                                <div　key={each_edu.id} className={classes.v_line_fix}></div>
                                <div>
                                    <div className={classes.experience1}>{each_edu['institution']}</div>
                                    <div className={classes.experience2}>{each_edu['field_of_study']}/{each_edu['degree']}</div>
                                    <div className={classes.experience2}>{each_edu['start_date']}-{each_edu['end_date']}</div>
                                </div>
                                <Link to='/education_edit/' style={{ textDecoration: 'none', color: '#424242' }}>
                                    <EditIcon 
                                    onClick={() => {
                                        dispatch(editEdu_id(each_edu['id']));
                                        dispatch(fetchAsyncUserProPut({'user_pro': user_pro, 'token': token}));
                                    }
                                    }
                                    />
                                </Link>
                            </div>
                        ))}
                    </div>
                    }
                </div>
                <div className={classes.head}>
                    <div>Who can view your private information?</div> 
                    <div>
                        <Who />
                    </div>    
                </div>
            </div>

            <div className={classes.head}>
                <div className={classes.private}>Optional information</div>
                <div>
                    <div className={classes.add_exp}>
                        <div>Experience</div> 
                        <Link to='/experience/'>
                            <button className={classes.exp_btn} onClick={edit_profile_experience}>+ add experience</button>
                        </Link>
                    </div>
                    { !(user_pro.experiences.length === 0) && 
                    <div className={classes.box}>
                        <div>
                            <div className={classes.experience1}>{user_pro.experiences[0]['position']}</div>
                            <div className={classes.experience2}>{user_pro.experiences[0]['ext_company']}</div>
                            <div className={classes.experience2}>{user_pro.experiences[0]['start_date']}-{user_pro.experiences[0]['is_current'] ? 'current' : user_pro.experiences[0]['end_date']}</div>
                            <Link to='/experience_edit/' style={{ textDecoration: 'none', color: '#424242' }}>
                                <EditIcon 
                                onClick={() => {
                                    dispatch(editExp_id(user_pro.experiences[0]['id']));
                                    dispatch(fetchAsyncUserProPut({'user_pro': user_pro, 'token': token}));
                                }
                                }
                                />
                            </Link>
                        </div>
                        {user_pro.experiences.slice(1).map((each_exp) => (
                            <div>
                                <div　key={each_exp.id} className={classes.v_line_fix}></div>
                                <div>
                                    <div className={classes.experience1}>{each_exp['position']}</div>
                                    <div className={classes.experience2}>{each_exp['ext_company']}</div>
                                    <div className={classes.experience2}>{each_exp['start_date']}-{each_exp['is_current'] ? 'current' : each_exp['end_date']}</div>
                                </div>
                                <Link to='/experience_edit/' style={{ textDecoration: 'none', color: '#424242' }}>
                                    <EditIcon 
                                    onClick={() => {
                                        dispatch(editExp_id(each_exp['id']));
                                        dispatch(fetchAsyncUserProPut({'user_pro': user_pro, 'token': token}));
                                    }
                                    }
                                    />
                                </Link>
                            </div>
                        ))}
                    </div>
                    }
                </div>

                <div className={classes.head}>
                    <div>Skills</div>
                    <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Professions"
                            size="49"
                            onChange={(e) => dispatch(editSkill_name(e.target.value))}
                        />
                    </div>
                    <AddIcon onClick={skill_post}/>
                    {user_pro.skills.map((each_skill) => (
                        <div key={each_skill.id} className={classes.skill_head}>
                            <div onClick={()=> setOpen_skill(true)} className={classes.skill}>
                                {each_skill.skill}<span 
                                                    onClick={() => {
                                                        dispatch(editSkill_id(each_skill.id));
                                                        dispatch(fetchAsyncUserProPut({'user_pro': user_pro, 'token': token}));
                                                    }
                                                    }
                                                    >   
                                                    X
                                                    </span>
                            </div>
                        </div>
                    ))}
                    <Dialog style={{textAlign: 'center'}}
                        open={open_skill}
                        onClose={skill_close}
                        aria-labelledby="logout-dialog-title"
                        aria-describedby="logout-dialog-description"
                    >
                        <DialogTitle id="logout-dialog-title">{""}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="logout-dialog-description">
                            Are you sure you want to delete?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={skill_close} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={skill_delete} color="primary" autoFocus>
                            Delete
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>


                <div className={classes.head}>
                    <div>Social Links</div> 
                    <div className={classes.social_head}>
                        <FacebookIcon className={classes.social_icon1}/>
                        <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Language"
                            style={{width:'320px'}}
                            value={user_pro.fb_link}
                            onChange={(e) => dispatch(editFB(e.target.value))}
                        />
                    </div>
                    </div>
                    <div className={classes.social_head}>
                        <LinkedInIcon className={classes.social_icon2}/>
                        <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Language"
                            style={{width:'320px'}}
                            value={user_pro.ln_link}
                            onChange={(e) => dispatch(editLN(e.target.value))}
                        />
                    </div>
                    </div>
                </div>

                <div className={classes.head}>
                    <div>Documents</div> 
                    <div className={classes.resume_head}>
                        <div className={classes.resume_str1}>Resume</div>
                        <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Language"
                            size="45"
                            value={user_pro.resume_link}
                            onChange={(e) => dispatch(editResume(e.target.value))}
                        />
                    </div>
                    </div>
                    <div className={classes.resume_head}>
                        <div className={classes.resume_str1}>Curriculum vitae</div>
                        <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Language"
                            size="45"
                            value={user_pro.cv_link}
                            onChange={(e) => dispatch(editCV(e.target.value))}
                        />
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withCookies(Profile_edit);