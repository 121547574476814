import React, { useEffect } from 'react';
import { withCookies } from "react-cookie";
import SettingsIcon from '@material-ui/icons/Settings';
import Avatar from '@material-ui/core/Avatar';
import classes from './Profile.module.css';
import TripOriginIcon from '@material-ui/icons/TripOrigin';
import MailIcon from '@material-ui/icons/Mail';
import TelegramIcon from '@material-ui/icons/Telegram';
import WcIcon from '@material-ui/icons/Wc';
import CakeIcon from '@material-ui/icons/Cake';
import Footer from '../footer/Footer';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import pic1 from '../card/1-Green1.png'
import pic2 from '../card/1-Green2.png'
import pic3 from '../card/1-Green3.png'
import pic4 from '../card/1-Green4.png'
import pic5 from '../card/1-Green5.png'
import pic6 from '../card/2-Bronze1.png'
import pic7 from '../card/2-Bronze2.png'
import pic8 from '../card/2-Bronze3.png'
import pic9 from '../card/2-Bronze4.png'
import pic10 from '../card/2-Bronze5.png'
import pic11 from '../card/3-Silver1.png'
import pic12 from '../card/3-Silver2.png'
import pic13 from '../card/3-Silver3.png'
import pic14 from '../card/3-Silver4.png'
import pic15 from '../card/3-Silver5.png'
import pic16 from '../card/4-Gold1.png'
import pic17 from '../card/4-Gold2.png'
import pic18 from '../card/4-Gold3.png'
import pic19 from '../card/4-Gold4.png'
import pic20 from '../card/4-Gold5.png'
// import pic21 from '../card/5-Platinum1.png'
// import pic22 from '../card/5-Platinum2.png'
// import pic23 from '../card/5-Platinum3.png'
// import pic24 from '../card/5-Platinum4.png'
import pic25 from '../card/5-Platinum5.png'
import pic26 from '../card/6-Black.png'
import LinearProgress from '@material-ui/core/LinearProgress';


import {
    selectProfile,
    fetchAsyncUserProGet,
} from "./profile_editSlice";

const Profile = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const user_pro = useSelector(selectProfile);
    const gender_dic = {'M': 'Male', 'F': 'Female', 'N': 'NA'}

    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);

    useEffect(() => {
        dispatch(fetchAsyncUserProGet(token))
    }, [dispatch]);

    const user_rank = (num) => {
        if (0 <= num & num <500) {
            return {'type': 'Green', 'level': 1, 'pic': pic1, 'level_lower': 0, 'level_upper': 499, 'rank_lower': 0, 'rank_upper': 9999}
        }
        if (500 <= num & num <2000) {
            return {'type': 'Green', 'level': 2, 'pic': pic2, 'level_lower': 500, 'level_upper': 1999, 'rank_lower': 0, 'rank_upper': 9999}
        }
        if (2000 <= num & num <4000) {
            return {'type': 'Green', 'level': 3, 'pic': pic3, 'level_lower': 2000, 'level_upper': 3999, 'rank_lower': 0, 'rank_upper': 9999}
        }
        if (4000 <= num & num <7000) {
            return {'type': 'Green', 'level': 4, 'pic': pic4, 'level_lower': 4000, 'level_upper': 6999, 'rank_lower': 0, 'rank_upper': 9999}
        }
        if (7000 <= num & num <10000) {
            return {'type': 'Green', 'level': 5, 'pic': pic5, 'level_lower': 7000, 'level_upper': 9999, 'rank_lower': 0, 'rank_upper': 9999}
        }
        if (10000 <= num & num <13000) {
            return {'type': 'Bronze', 'level': 1, 'pic': pic6, 'level_lower': 10000, 'level_upper': 12999, 'rank_lower': 10000, 'rank_upper': 24999}
        }
        if (13000 <= num & num <16000) {
            return {'type': 'Bronze', 'level': 2, 'pic': pic7, 'level_lower': 13000, 'level_upper': 15999, 'rank_lower': 10000, 'rank_upper': 24999}
        }
        if (16000 <= num & num <19000) {
            return {'type': 'Bronze', 'level': 3, 'pic': pic8, 'level_lower': 16000, 'level_upper': 18999, 'rank_lower': 10000, 'rank_upper': 24999}
        }
        if (19000 <= num & num <21000) {
            return {'type': 'Bronze', 'level': 4, 'pic': pic9, 'level_lower': 19000, 'level_upper': 20999, 'rank_lower': 10000, 'rank_upper': 24999}
        }
        if (21000 <= num & num <25000) {
            return {'type': 'Bronze', 'level': 5, 'pic': pic10, 'level_lower': 21000, 'level_upper': 24999, 'rank_lower': 10000, 'rank_upper': 24999}
        }
        if (25000 <= num & num <29000) {
            return {'type': 'Silver', 'level': 1, 'pic': pic11, 'level_lower': 25000, 'level_upper': 28999, 'rank_lower': 25000, 'rank_upper': 49999}
        }
        if (29000 <= num & num <33000) {
            return {'type': 'Silver', 'level': 2, 'pic': pic12, 'level_lower': 29000, 'level_upper': 32999, 'rank_lower': 25000, 'rank_upper': 49999}
        }
        if (33000 <= num & num <38000) {
            return {'type': 'Silver', 'level': 3, 'pic': pic13, 'level_lower': 33000, 'level_upper': 37999, 'rank_lower': 25000, 'rank_upper': 49999}
        }
        if (38000 <= num & num <43000) {
            return {'type': 'Silver', 'level': 4, 'pic': pic14, 'level_lower': 38000, 'level_upper': 42999, 'rank_lower': 25000, 'rank_upper': 49999}
        }
        if (43000 <= num & num <50000) {
            return {'type': 'Silver', 'level': 5, 'pic': pic15, 'level_lower': 43000, 'level_upper': 49999, 'rank_lower': 25000, 'rank_upper': 49999}
        }
        if (50000 <= num & num <58000) {
            return {'type': 'Gold', 'level': 1, 'pic': pic16, 'level_lower': 50000, 'level_upper': 57999, 'rank_lower': 50000, 'rank_upper': 99999}
        }
        if (58000 <= num & num <67000) {
            return {'type': 'Gold', 'level': 2, 'pic': pic17, 'level_lower': 58000, 'level_upper': 66999, 'rank_lower': 50000, 'rank_upper': 99999}
        }
        if (67000 <= num & num <77000) {
            return {'type': 'Gold', 'level': 3, 'pic': pic18, 'level_lower': 67000, 'level_upper': 76999, 'rank_lower': 50000, 'rank_upper': 99999}
        }
        if (77000 <= num & num <88000) {
            return {'type': 'Gold', 'level': 4, 'pic': pic19, 'level_lower': 77000, 'level_upper': 87999, 'rank_lower': 50000, 'rank_upper': 99999}
        }
        if (88000 <= num & num <100000) {
            return {'type': 'Gold', 'level': 5, 'pic': pic20, 'level_lower': 88000, 'level_upper': 99999, 'rank_lower': 50000, 'rank_upper': 99999}
        }
        if (100000 <= num & num <100000000000) {
            return {'type': 'Platinum', 'level': 5, 'pic': pic25, 'level_lower': 100000, 'level_upper': 999999, 'rank_lower': 100000, 'rank_upper': 999999}
        }
    }


    return (
        <div className={classes.header}>
            <div>
                <div className={classes.header}>
                    <Link to='/setting/' className={classes.icon}>
                        <SettingsIcon/>
                    </Link>
                </div>
                <div className={classes.name}>
                    <Avatar src={user_pro.avatar_url}/>
                    <div>
                        <div className={classes.name_color}>{user_pro.first_name} {user_pro.last_name}</div>
                        <div>{user_pro.position}</div>
                        <div>{user_pro.company_name}</div>
                    </div>
                </div>
                <div className={classes.card}>
                    <img src={!user_pro.card.is_black ? user_rank(user_pro.card.number_of_points)['pic'] : pic26} style={{height: '200px'}}/>
                </div>
            </div>
            <div>
                <div className={classes.card_box}>
                    <div>{user_pro.card.number_of_points.toLocaleString()} AXP</div>
                    <div>
                        <div>Rank: {user_rank(user_pro.card.number_of_points)['type']}</div>
                        <div>Level: {user_rank(user_pro.card.number_of_points)['level']}</div>
                    </div>
                </div>
                <div className={classes.level_box}>
                    <div>Next Level</div>
                    <LinearProgress variant="determinate" 
                     value={(user_pro.card.number_of_points - user_rank(user_pro.card.number_of_points)['level_lower'])/(user_rank(user_pro.card.number_of_points)['level_upper'] - user_rank(user_pro.card.number_of_points)['level_lower']) * 100} 
                     style={{height: '20px'}}
                    />
                    <div className={classes.level}>
                        <div>{user_rank(user_pro.card.number_of_points)['level_lower'].toLocaleString()}</div>
                        <div>{user_rank(user_pro.card.number_of_points)['level_upper'].toLocaleString()}</div>
                    </div>
                </div>
                <div className={classes.level_box}>
                    <div>Next Rank
                    </div>
                    <LinearProgress variant="determinate" 
                     value={(user_pro.card.number_of_points - user_rank(user_pro.card.number_of_points)['rank_lower'])/(user_rank(user_pro.card.number_of_points)['rank_upper'] - user_rank(user_pro.card.number_of_points)['rank_lower']) * 100} 
                     style={{height: '20px'}}
                    />
                    <div className={classes.level}>
                        <div>{user_rank(user_pro.card.number_of_points)['rank_lower'].toLocaleString()}</div>
                        <div>{user_rank(user_pro.card.number_of_points)['rank_upper'].toLocaleString()}</div>
                    </div>
                </div>
            </div>
            <div className={classes.line}></div>
            <div>
                <div>
                    <div className={classes.inf}>
                        <TripOriginIcon/>
                        <div className={classes.size}>1st Node {user_pro.first_node_count}, 2nd Node {user_pro.second_node_count}</div>
                        <Link to='/profile_edit/'  className={classes.edit}>
                            <EditIcon />
                        </Link>
                    </div>
                    <div className={classes.inf}>
                        <MailIcon/>
                        <div className={classes.size}>{user_pro.email}</div>
                    </div>
                    <div className={classes.inf}>
                        <TelegramIcon/>
                        <div className={classes.size}>{user_pro.location}</div>
                    </div>
                    <div className={classes.inf}>
                        <WcIcon/>
                        <div className={classes.size}>{gender_dic[user_pro.gender]}</div>
                        <CakeIcon/>
                        <div className={classes.size}>{user_pro.birth_date}</div>
                    </div>
                </div>
            </div>
            <div className={classes.head}>
                <div>Professions</div> 
                <div className={classes.profession}>{user_pro.professions[0]}</div>
                <div className={classes.profession}>{user_pro.professions[1]}</div>
                <div className={classes.profession}>{user_pro.professions[2]}</div>
            </div>

            <div className={classes.head}>
                <div>Skills</div> 
                <div className={classes.skill_head}>
                { user_pro.skills.map((each_skill) => ( 
                    <div key={each_skill.id} className={classes.skill}>{each_skill.skill}</div>
                ))}
                </div> 
            </div>

            <div className={classes.head}>
                <div>Language</div> 
                <div className={classes.skill_head}>
                    { user_pro.languages.map((each_language) => ( 
                        <div key={each_language.id} className={classes.skill}>{each_language.language}</div>
                    ))}
                </div>
            </div>

            <div className={classes.head}>
                <div>Education</div> 
                { !(user_pro.educations.length === 0) && 
                    <div className={classes.box}>
                        <div>
                            <div className={classes.experience1}>{user_pro.educations[0]['institution']}</div>
                            <div className={classes.experience2}>{user_pro.educations[0]['field_of_study']}/{user_pro.educations[0]['degree']}</div>
                            <div className={classes.experience2}>{user_pro.educations[0]['start_date']}-{user_pro.educations[0]['end_date']}</div>
                        </div>
                        {user_pro.educations.slice(1).map((each_edu) => (
                            <div key={each_edu.id}>
                                <div　className={classes.v_line_fix}></div>
                                <div>
                                    <div className={classes.experience1}>{each_edu['institution']}</div>
                                    <div className={classes.experience2}>{each_edu['field_of_study']}/{each_edu['degree']}</div>
                                    <div className={classes.experience2}>{each_edu['start_date']}-{each_edu['end_date']}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>

            <div className={classes.head}>
                <div>Experiencd</div> 
                { !(user_pro.experiences.length === 0) && 
                    <div className={classes.box}>
                        <div>
                            <div className={classes.experience1}>{user_pro.experiences[0]['position']}</div>
                            <div className={classes.experience2}>{user_pro.experiences[0]['ext_company']}</div>
                            <div className={classes.experience2}>{user_pro.experiences[0]['start_date']}-{user_pro.experiences[0]['is_current'] ? 'current' : user_pro.experiences[0]['end_date']}</div>
                        </div>
                        {user_pro.experiences.slice(1).map((each_exp) => (
                            <div key={each_exp.id}>
                                <div　className={classes.v_line_fix}></div>
                                <div>
                                    <div className={classes.experience1}>{each_exp['position']}</div>
                                    <div className={classes.experience2}>{each_exp['ext_company']}</div>
                                    <div className={classes.experience2}>{each_exp['start_date']}-{each_exp['is_current'] ? 'current' : each_exp['end_date']}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>

            <div className={classes.head}>
                <div>Social Links</div> 
                <div className={classes.social_head}>
                    <FacebookIcon className={classes.social_icon1}/>
                    <a href={user_pro.fb_link}>{user_pro.fb_link}</a>
                </div>
                <div className={classes.social_head}>
                    <LinkedInIcon className={classes.social_icon2}/>
                    <a href={user_pro.ln_link}>{user_pro.ln_link}</a>
                </div>
            </div>

            <div className={classes.head}>
                <div>Documents</div> 
                <div className={classes.resume_head}>
                    <div className={classes.resume_str1}>Resume</div>
                    <a href={user_pro.resume_link} className={classes.resume_str2} target="_blank">{user_pro.resume_link}</a>
                </div>
                <div className={classes.resume_head}>
                    <div className={classes.resume_str1}>CV</div>
                    <a href={user_pro.cv_link} className={classes.resume_str2} target="_blank">{user_pro.cv_link}</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default withCookies(Profile)
