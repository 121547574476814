import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = "https://www.axelnode-dashboard.com/";

export const fetchAsyncActivatePut = createAsyncThunk('activate/put', async (activate_token) => {
    const createData = new FormData();
    createData.append('token', activate_token)
    const res = await axios.put(`${apiUrl}api/v1/users/activate_user/`, createData, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
} 
)

const activateSlice = createSlice({
    name: 'activate_info',
    initialState: {
    },
    reducers: {
        editActivate_token(state, action) {
            state.activate_token = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncActivatePut.fulfilled, (state, action) => {
            window.location.href = '/login';
        })
    },
})

export default activateSlice.reducer;


