import React, { useEffect, useState } from 'react';
import { withCookies } from "react-cookie";
import classes from './Reset_pas.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from "react-redux";

import {
    editToken, 
    editReset_pas,
    fetchAsyncReset_pasPut,
    selectReset_pas,
} from "./reset_pasSlice";

const Reset_pas = (props) => {
    const reset_token = window.location.href.slice(-100)
    const dispatch = useDispatch();
    const Reset_pas = useSelector(selectReset_pas);
    const [repeat, setRepeat] = useState('')
    const btnDisabler = !(repeat === Reset_pas.reset_pas)
    const [open, setOpen] = useState(false)
    const token = props.cookies.get("current-token");

    const close = () => {
        setOpen(false)
    }

    const modal_open = () => {
        setOpen(true)
        dispatch(editToken(reset_token))

    }

    const reset_pas = async () => {
        dispatch(fetchAsyncReset_pasPut(Reset_pas))
        setOpen(false)
    }

    return (
        <div>
            <div className={classes.header}>
                <Link to='/setting/'>
                    <button className={classes.left}><ArrowBackIosIcon /></button>
                </Link>
                <span className={classes.center}>Reset Password</span>
            </div>

            <div className={classes.head}>
                <div>New Password</div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="text"
                        name="Reset"
                        size="49"
                        onChange={(e) => dispatch(editReset_pas(e.target.value))}
                    />
                </div>
            </div>

            <div className={classes.head}>
                <div>Repeat New Password
                </div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="text"
                        name="Repeat"
                        size="49"
                        onChange={(e) => setRepeat(e.target.value)}
                    />
                </div>
            </div>
            <div>
                <div className={classes.btnpos}>
                    <button
                        variant="contained"
                        className={classes.tag1}
                        onClick={modal_open}
                        disabled={btnDisabler}
                    >
                        Reset
                    </button>
                </div>
                <Dialog style={{textAlign: 'center'}}
                    open={open}
                    onClose={close}
                    aria-labelledby="logout-dialog-title"
                    aria-describedby="logout-dialog-description"
                >
                    <DialogTitle id="logout-dialog-title">{""}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="logout-dialog-description">
                        Are you sure you want to Reset?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <button onClick={close} color="primary">
                        Cancel
                    </button>
                    <button onClick={reset_pas} color="primary" autoFocus>
                        OK
                    </button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default withCookies(Reset_pas)
