
import { configureStore } from '@reduxjs/toolkit';
import loginReducer from '../features/login/loginSlice';
import RegisterReducer from '../features/createtwo/createtwoSlice';
import ProfileReducer from '../features/profile/profile_editSlice';
import NoticesReducer from '../features/notices/noticesSlice';
import ActivateReducer from '../features/activate/activateSlice';
import EmailReducer from '../features/forgotpas/forgotpasSlice';
import changeReducer from '../features/change/changeSlice';
import resetReducer from '../features/reset_pas/reset_pasSlice';
import invitationReducer from '../features/invitation/invitationSlice';
import subticketReducer from '../features/open_ticket/open_ticketSlice';
import notificationReducer from '../features/notification/notificationSlice';
import avatar_infoReducer from '../features/node_list/node_profileSlice';

export default configureStore({
  reducer: {
    login: loginReducer,
    register: RegisterReducer,
    profile: ProfileReducer,
    notices: NoticesReducer,
    activate_info: ActivateReducer,
    email: EmailReducer,
    change_password: changeReducer,
    reset_password: resetReducer,
    invitation: invitationReducer,
    subticket: subticketReducer,
    notification: notificationReducer,
    other_user_info: avatar_infoReducer,
  },
});
