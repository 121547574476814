import React, {useEffect} from 'react';
import { withCookies } from 'react-cookie';
import classes from './Refer.module.css';
import Avatar from '@material-ui/core/Avatar';
import Icon from './AXN_Logo_Horizontal.png';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import {
    selectTicket,
    selectChain,
    editSubticket_status, 
} from "../open_ticket/open_ticketSlice";

const Refer = (props) => {
    const dispatch = useDispatch();
    const ticket = useSelector(selectTicket);
    const chain = useSelector(selectChain);
    const token = props.cookies.get("current-token");

    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);

    useEffect(async() => {
        if (ticket.id === 0) {
            window.location.href = '/open_ticket';
        }
    }, [dispatch]);
    return (
        <div>
            <div>
                <div className={classes.head}>
                    <span className={classes.center}>Business Development</span>
                </div>
            </div>
            <div className={classes.top}>Choose the way to refer</div>
            <div className={classes.set}>
                {chain.parent_user != null &&
                    <div className={classes.set}>
                        <div className={classes.first}></div>
                        <Avatar src={chain.parent_user.avatar_url}/>
                    </div>
                }
                <div className={classes.set}>
                    <div className={classes.second}></div>
                    <Avatar src={chain.user.avatar_url}/>
                </div>
                <div className={classes.set}>
                    <div className={classes.second}></div>
                    <Avatar />
                </div>
            </div>
            <div className={classes.smalltop}>If your friend is not in your 1st node on Axelnode, you have to invite your friend through “Other App”.</div>
            
            <Link to='/refer_ax/'>
                <div className={classes.pos} onClick={() => dispatch(editSubticket_status('referred'))}>
                    <img src={Icon} />
                </div>
            </Link>

            <Link to='/refer_oth/' style={{ textDecoration: 'none' }}>
                <div className={classes.pos} onClick={() => dispatch(editSubticket_status('referred_free'))}>
                    <div>Other App</div>
                </div>
            </Link>

            {/* <div className={classes.footer}>
            <Link to='open_ticket'>
                <button className={classes.app}>Next</button>
            </Link>
            </div> */}
        </div>
    )
}

export default withCookies(Refer)
