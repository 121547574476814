import React, {useEffect} from 'react';
import Icon from './AXN_Logo_Horizontal.png';
import classes from './Login.module.css';
import { withCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import {
  editEmail,
  editPassword,
  fetchAsyncUserGet,
  fetchAsyncLogin,
  selectAuthen,
  selectError,
} from "./loginSlice";


const Login = (props) => {
    const dispatch = useDispatch();
    const authen = useSelector(selectAuthen);
    const error = useSelector(selectError);
    const btnDisabler = authen.username === "" || authen.password === "";

    const login = async() => {
        const result = await dispatch(fetchAsyncLogin(authen))

        if (fetchAsyncLogin.fulfilled.match(result)) {
            props.cookies.set("current-token", result.payload.access_token, {maxAge: 3600, path: '/'});
            const res = await dispatch(fetchAsyncUserGet(result.payload.access_token))

            if (fetchAsyncUserGet.fulfilled.match(res)) {
                props.cookies.set("user_id", res.payload.id, {maxAge: 3600, path: '/'});
                if (res.payload.has_access == true) {
                    window.location.href = '/open_ticket/';
                }
                else {
                    window.location.href = '/sub_ticket/';
                }
            }
        }
        else {
        }
    }

    return (
        <div className={classes.header}>
            <div>
                <img className={classes.img} src={Icon}/>
            </div>
            <div>
                <div className={classes.form}>
                    <input className={classes.input}
                        type="text"
                        name="Email"
                        placeholder="Email"
                        onChange={(e) => dispatch(editEmail(e.target.value))}
                        required
                    />
                </div>
                <hr className={classes.hr}/>
                <div className={classes.form}>
                    <input className={classes.input}
                        type="password"
                        name="Password"
                        placeholder="Password"
                        onChange={(e) => dispatch(editPassword(e.target.value))}
                        required
                    />
                </div>
                <hr className={classes.hr}/>

                <div className={classes.note}>
                    <h2 className={classes.note1}>{error.slice(0, 14)}</h2>
                    <button className={classes.note2}>
                        <Link to='/forgotPas/' style={{ textDecoration: 'none', color: '#424242' }}>Forgot Password?</Link>
                    </button>
                </div>

                <div className={classes.head}>
                    <button
                        variant="contained"
                        className={classes.tag}
                        color="primary"
                        disabled={btnDisabler}
                        onClick={login}
                    >
                        Login with Email
                    </button>
                    <br/>
                    <button className={classes.string}>
                        <Link to='/createOne/' style={{ textDecoration: 'none', color: '#424242' }}> Do not have an account?</Link>
                    </button>
                </div>
                
            </div>
            
            
            
        </div>
    )
}

export default withCookies(Login)
