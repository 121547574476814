import React, {useEffect} from 'react';
import { withCookies } from "react-cookie";
import classes from './Apply_comp.module.css';
import Avatar from '@material-ui/core/Avatar';
import Ticket2 from './Ticket2';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import {
    selectAfter_ticekt,
    fetchAsyncSubticket_afterGet,
} from "../open_ticket/open_ticketSlice";

const Apply_comp = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const after_ticek_id = props.cookies.get("after_ticek_id");
    const after_ticket = useSelector(selectAfter_ticekt);

    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);

    useEffect(() => {
        dispatch(fetchAsyncSubticket_afterGet({'token': token, 'uuid': after_ticek_id}))
    }, [dispatch]);

    return (
        <div>
            <div　className={classes.header}>
                <div className={classes.instr}>Congratulations!</div>
            </div>
            <Ticket2 after_ticket={after_ticket}/>
            <div className={classes.set}>
                {after_ticket.parent != null &&
                    <div className={classes.set}>
                        <div className={classes.second}></div>
                        <Avatar src={after_ticket.parent.user.avatar_url}/>
                    </div>
                }
                <div className={classes.set}>
                    <div className={classes.second}></div>
                    <Avatar src={after_ticket.user.avatar_url}/>
                </div>
            </div>
            <div className={classes.head}>
                <div>Axelnode has connected!</div>
                <div className={classes.str}>Let's wait for contact from company.</div>
            </div>
            
            <Link to='/open_ticket/' style={{ textDecoration: 'none', color: '#424242' }}>
                <div className={classes.iconhead}>
                    <ExpandMoreIcon className={classes.icon} />
                </div>
            </Link>
            
            
        </div>
    )
}

export default withCookies(Apply_comp)
