import React from 'react'
import classes from './Conditions.module.css'

const Conditions = ({ticket}) => {
    return (
        <div>
            <div className={classes.box}>
                <div className={classes.top}>Conditions</div>
                <div className={classes.head}>
                    <div className={classes.str1}>Compensation</div>
                    <div className={classes.str2}>{ticket.compensation}</div>
                </div>
                <div className={classes.head}>
                    <div className={classes.str1}>Location</div>
                    <div className={classes.str2}>{ticket.location}</div>
                </div>
            </div>
        </div>
    )
}

export default Conditions
