import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { withCookies } from "react-cookie";
import classes from './Node_profile.module.css';
import TripOriginIcon from '@material-ui/icons/TripOrigin';
import MailIcon from '@material-ui/icons/Mail';
import TelegramIcon from '@material-ui/icons/Telegram';
import WcIcon from '@material-ui/icons/Wc';
import CakeIcon from '@material-ui/icons/Cake';
import Footer from '../footer/Footer';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { useSelector, useDispatch } from "react-redux";
import pic1 from '../card/1-Green1.png'
import pic2 from '../card/1-Green2.png'
import pic3 from '../card/1-Green3.png'
import pic4 from '../card/1-Green4.png'
import pic5 from '../card/1-Green5.png'
import pic6 from '../card/2-Bronze1.png'
import pic7 from '../card/2-Bronze2.png'
import pic8 from '../card/2-Bronze3.png'
import pic9 from '../card/2-Bronze4.png'
import pic10 from '../card/2-Bronze5.png'
import pic11 from '../card/3-Silver1.png'
import pic12 from '../card/3-Silver2.png'
import pic13 from '../card/3-Silver3.png'
import pic14 from '../card/3-Silver4.png'
import pic15 from '../card/3-Silver5.png'
import pic16 from '../card/4-Gold1.png'
import pic17 from '../card/4-Gold2.png'
import pic18 from '../card/4-Gold3.png'
import pic19 from '../card/4-Gold4.png'
import pic20 from '../card/4-Gold5.png'
// import pic21 from '../card/5-Platinum1.png'
// import pic22 from '../card/5-Platinum2.png'
// import pic23 from '../card/5-Platinum3.png'
// import pic24 from '../card/5-Platinum4.png'
import pic25 from '../card/5-Platinum5.png'
import pic26 from '../card/6-Black.png'

import {
    selectOther_info,
    fetchAsyncOther_userGet,
} from "../node_list/node_profileSlice";

const Node_profile = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const other_info = useSelector(selectOther_info);
    const gender_dic = {'M': 'Male', 'F': 'Female', 'N': 'NA'}
    
    const other_id = (number) => {
        let dir = window.location.href.split("/");
        let dir2 = dir[dir.length - number];
        return dir2;
    }


    useEffect(() => {
        dispatch(fetchAsyncOther_userGet({'user_id': other_id(2), 'token': token}))
    }, [dispatch]);

    const user_rank = (num) => {
        if (0 <= num & num <500) {
            return {'type': 'Green', 'level': 1, 'pic': pic1, 'level_lower': 0, 'level_upper': 499, 'rank_lower': 0, 'rank_upper': 9999}
        }
        if (500 <= num & num <2000) {
            return {'type': 'Green', 'level': 2, 'pic': pic2, 'level_lower': 500, 'level_upper': 1999, 'rank_lower': 0, 'rank_upper': 9999}
        }
        if (2000 <= num & num <4000) {
            return {'type': 'Green', 'level': 3, 'pic': pic3, 'level_lower': 2000, 'level_upper': 3999, 'rank_lower': 0, 'rank_upper': 9999}
        }
        if (4000 <= num & num <7000) {
            return {'type': 'Green', 'level': 4, 'pic': pic4, 'level_lower': 4000, 'level_upper': 6999, 'rank_lower': 0, 'rank_upper': 9999}
        }
        if (7000 <= num & num <10000) {
            return {'type': 'Green', 'level': 5, 'pic': pic5, 'level_lower': 7000, 'level_upper': 9999, 'rank_lower': 0, 'rank_upper': 9999}
        }
        if (10000 <= num & num <13000) {
            return {'type': 'Bronze', 'level': 1, 'pic': pic6, 'level_lower': 10000, 'level_upper': 12999, 'rank_lower': 10000, 'rank_upper': 24999}
        }
        if (13000 <= num & num <16000) {
            return {'type': 'Bronze', 'level': 2, 'pic': pic7, 'level_lower': 13000, 'level_upper': 15999, 'rank_lower': 10000, 'rank_upper': 24999}
        }
        if (16000 <= num & num <19000) {
            return {'type': 'Bronze', 'level': 3, 'pic': pic8, 'level_lower': 16000, 'level_upper': 18999, 'rank_lower': 10000, 'rank_upper': 24999}
        }
        if (19000 <= num & num <21000) {
            return {'type': 'Bronze', 'level': 4, 'pic': pic9, 'level_lower': 19000, 'level_upper': 20999, 'rank_lower': 10000, 'rank_upper': 24999}
        }
        if (21000 <= num & num <25000) {
            return {'type': 'Bronze', 'level': 5, 'pic': pic10, 'level_lower': 21000, 'level_upper': 24999, 'rank_lower': 10000, 'rank_upper': 24999}
        }
        if (25000 <= num & num <29000) {
            return {'type': 'Silver', 'level': 1, 'pic': pic11, 'level_lower': 25000, 'level_upper': 28999, 'rank_lower': 25000, 'rank_upper': 49999}
        }
        if (29000 <= num & num <33000) {
            return {'type': 'Silver', 'level': 2, 'pic': pic12, 'level_lower': 29000, 'level_upper': 32999, 'rank_lower': 25000, 'rank_upper': 49999}
        }
        if (33000 <= num & num <38000) {
            return {'type': 'Silver', 'level': 3, 'pic': pic13, 'level_lower': 33000, 'level_upper': 37999, 'rank_lower': 25000, 'rank_upper': 49999}
        }
        if (38000 <= num & num <43000) {
            return {'type': 'Silver', 'level': 4, 'pic': pic14, 'level_lower': 38000, 'level_upper': 42999, 'rank_lower': 25000, 'rank_upper': 49999}
        }
        if (43000 <= num & num <50000) {
            return {'type': 'Silver', 'level': 5, 'pic': pic15, 'level_lower': 43000, 'level_upper': 49999, 'rank_lower': 25000, 'rank_upper': 49999}
        }
        if (50000 <= num & num <58000) {
            return {'type': 'Gold', 'level': 1, 'pic': pic16, 'level_lower': 50000, 'level_upper': 57999, 'rank_lower': 50000, 'rank_upper': 99999}
        }
        if (58000 <= num & num <67000) {
            return {'type': 'Gold', 'level': 2, 'pic': pic17, 'level_lower': 58000, 'level_upper': 66999, 'rank_lower': 50000, 'rank_upper': 99999}
        }
        if (67000 <= num & num <77000) {
            return {'type': 'Gold', 'level': 3, 'pic': pic18, 'level_lower': 67000, 'level_upper': 76999, 'rank_lower': 50000, 'rank_upper': 99999}
        }
        if (77000 <= num & num <88000) {
            return {'type': 'Gold', 'level': 4, 'pic': pic19, 'level_lower': 77000, 'level_upper': 87999, 'rank_lower': 50000, 'rank_upper': 99999}
        }
        if (88000 <= num & num <100000) {
            return {'type': 'Gold', 'level': 5, 'pic': pic20, 'level_lower': 88000, 'level_upper': 99999, 'rank_lower': 50000, 'rank_upper': 99999}
        }
        if (100000 <= num & num <100000000000) {
            return {'type': 'Platinum', 'level': 5, 'pic': pic25, 'level_lower': 100000, 'level_upper': 999999, 'rank_lower': 100000, 'rank_upper': 999999}
        }
    }

    return (
        <div className={classes.header}>
            <div>
                <div>
                    <div className={classes.top}>
                        <span className={classes.center}>Node Profile</span>
                    </div>
                </div>
                <div className={classes.name}>
                    <Avatar src={other_info.avatar_url}/>
                    <div>
                        <div className={classes.name_color}>{other_info.first_name} {other_info.last_name}</div>
                        <div>{other_info.position}</div>
                        <div>{other_info.company_name}</div>
                    </div>
                </div>
                <div className={classes.card}>
                    <img src={!other_info.card.is_black ? user_rank(other_info.card.number_of_points)['pic'] : pic26} style={{height: '200px'}}/>
                </div>
            </div>
            <div className={classes.line}></div>
            <div>
                <div>
                    <div className={classes.inf}>
                        <TripOriginIcon />
                        <div className={classes.size}>1st Node {other_info.first_node_count}, 2nd Node {other_info.second_node_count}</div>
                    </div>
                    <div className={classes.inf}>
                        <MailIcon/>
                        <div className={classes.size}>{other_info.email}</div>
                    </div>
                    <div className={classes.inf}>
                        <TelegramIcon/>
                        <div className={classes.size}>{other_info.location}</div>
                    </div>
                    <div className={classes.inf}>
                        <WcIcon/>
                        <div className={classes.size}>{gender_dic[other_info.gender]}</div>
                        <CakeIcon/>
                        <div className={classes.size}>{other_info.birth_date}</div>
                    </div>
                </div>
            </div>
            <div className={classes.head}>
                <div>Professions</div> 
                <div className={classes.profession}>{other_info.professions[0]}</div>
                <div className={classes.profession}>{other_info.professions[1]}</div>
                <div className={classes.profession}>{other_info.professions[2]}</div>
            </div>

            <div className={classes.head}>
                <div>Skills</div> 
                <div className={classes.skill_head}>
                { other_info.skills.map((each_skill) => ( 
                    <div className={classes.skill}>{each_skill.skill}</div>
                ))}
                </div> 
            </div>

            <div className={classes.head}>
                <div>Language</div> 
                <div className={classes.skill_head}>
                    { other_info.languages.map((each_language) => ( 
                        <div className={classes.skill}>{each_language.language}</div>
                    ))}
                </div>
            </div>

            <div className={classes.head}>
                <div>Education</div> 
                { !(other_info.educations.length === 0) && 
                    <div className={classes.box}>
                        <div>
                            <div className={classes.experience1}>{other_info.educations[0]['institution']}</div>
                            <div className={classes.experience2}>{other_info.educations[0]['field_of_study']}/{other_info.educations[0]['degree']}</div>
                            <div className={classes.experience2}>{other_info.educations[0]['start_date']}-{other_info.educations[0]['end_date']}</div>
                        </div>
                        {other_info.educations.slice(1).map((each_edu) => (
                            <div>
                                <div　className={classes.v_line_fix}></div>
                                <div>
                                    <div className={classes.experience1}>{each_edu['institution']}</div>
                                    <div className={classes.experience2}>{each_edu['field_of_study']}/{each_edu['degree']}</div>
                                    <div className={classes.experience2}>{each_edu['start_date']}-{each_edu['end_date']}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>

            <div className={classes.head}>
                <div>Experiencd</div> 
                { !(other_info.experiences.length === 0) && 
                    <div className={classes.box}>
                        <div>
                            <div className={classes.experience1}>{other_info.experiences[0]['position']}</div>
                            <div className={classes.experience2}>{other_info.experiences[0]['ext_company']}</div>
                            <div className={classes.experience2}>{other_info.experiences[0]['start_date']}-{other_info.experiences[0]['is_current'] ? 'current' : other_info.experiences[0]['end_date']}</div>
                        </div>
                        {other_info.experiences.slice(1).map((each_exp) => (
                            <div>
                                <div　className={classes.v_line_fix}></div>
                                <div>
                                    <div className={classes.experience1}>{each_exp['position']}</div>
                                    <div className={classes.experience2}>{each_exp['ext_company']}</div>
                                    <div className={classes.experience2}>{each_exp['start_date']}-{each_exp['is_current'] ? 'current' : each_exp['end_date']}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>

            <div className={classes.head}>
                <div>Social Links</div> 
                <div className={classes.social_head}>
                    <FacebookIcon className={classes.social_icon1}/>
                    <a href={other_info.fb_link} target="_blank">{other_info.fb_link}</a>
                </div>
                <div className={classes.social_head}>
                    <LinkedInIcon className={classes.social_icon2}/>
                    <a href={other_info.ln_link} target="_blank">{other_info.ln_link}</a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default withCookies(Node_profile)
