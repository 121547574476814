import React, {useState, useEffect} from 'react';
import { withCookies } from 'react-cookie';
import Icon from './AXN_Logo_Horizontal.png';
import classes from './Invitation.module.css';
import { useSelector, useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import invitationSlice, {
    selectError,
    fetchAsyncUserInvitationPut,
    fetchAsyncExt_sub_tickeGet,
    selectUUID,
} from "./invitationSlice";

const Invitation = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const uuid = useSelector(selectUUID);
    const error = useSelector(selectError);
    const [code, setCode] = useState('')
    const Logout = () =>  {
        props.cookies.remove('current-token');
        props.cookies.remove('user_id');
        window.location.href = '/';
    }
    const take_uuid = (code) => {
        dispatch(fetchAsyncExt_sub_tickeGet({'code': code, 'token': token}))
        setOpen(true);
    }
    const use_uuid = () => {
        dispatch(fetchAsyncUserInvitationPut({'uuid': uuid, 'token': token}))
    }
    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);

    

    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
      };
    const handleClose = () => {
        setOpen(false);
    };

    


    return (
        <div className={classes.header}>
            <div>
                <img className={classes.img} src={Icon}/>
            </div>
            <div>
                <div>
                    <div className={classes.cp_iptxt1}>
                        <input className={classes.input}
                            type="text"
                            name="Invitation_ID"
                            placeholder="Invitation ID"
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                    </div>
                </div>
                <hr className={classes.hr}/>
                <div>
                    <button
                        variant="contained"
                        className={classes.confirm_btn}
                        color="primary"
                        onClick={() => take_uuid(code)}
                    >
                        Confirm
                    </button>
                    <Dialog style={{textAlign: 'center'}}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="logout-dialog-title"
                        aria-describedby="logout-dialog-description"
                    >
                        <DialogTitle id="logout-dialog-title">{""}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="logout-dialog-description">
                            Are you sure you want to use this code?
                            <div style={{color: 'red'}}>{error}</div>
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            No
                        </Button>
                        <Button onClick={use_uuid} color="primary" autoFocus>
                            Yes
                        </Button>
                        </DialogActions>
                    </Dialog>   
                    <br/>
                    <button className={classes.skip_btn} onClick={Logout}>
                        Skip
                    </button>
                </div>
                
            </div>
            
            
            
        </div>
    )
}

export default withCookies(Invitation)
