import React, {useState, useEffect} from 'react'
import classes from './Node_list.module.css';
import { withCookies } from "react-cookie";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Footer from '../footer/Footer';
import SettingsIcon from '@material-ui/icons/Settings';
import { Link } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import { useSelector, useDispatch } from "react-redux";


import {
    selectNode_list,
    editOther_user_id,
    fetchAsyncNode_listGet,
} from "../node_list/node_profileSlice";


const Node_list = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const [toggle2, setToggle2] = useState(true);
    const [toggle3, setToggle3] = useState(true);
    const node_list = useSelector(selectNode_list);

    const first = node_list.results.filter((output) => {
        return output.contact_type == "first";
    })

    const second = node_list.results.filter((output) => {
        return output.contact_type == "second";
    })

    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);


    useEffect(async() => {
        dispatch(fetchAsyncNode_listGet(token))
    }, [dispatch]);


    return (
        <div className={classes.body}>
            <div className={classes.header1}>
                <div>Node List</div>
                <Link to='/setting/'>
                    <div className={classes.btn1}>
                        <SettingsIcon/>
                    </div>
                </Link>
            </div>
            
            <div className={classes.header2}>
                <div>1st Node</div>
                <div className={classes.top}>
                    <div>{first.length}</div>
                    <button className={classes.btn2} onClick={() => setToggle2(!toggle2)}>
                        <ArrowDropDownIcon />
                    </button>
                </div>
            </div>
            { toggle2 ?
                <div className={classes.nodes_head}>
                    {first.map((fir) => (
                    <Link key={fir.id} to={`/node_profile/${fir.contact.id}/`} onClick={() => dispatch(editOther_user_id(fir.contact.id))}　style={{ textDecoration: 'none', color: '#424242' }}>
                        <div className={classes.nodes_box}>
                            <Avatar src={fir.contact.avatar_url} className={classes.avatar}/>
                            <div className={classes.avatar_name}>{fir.contact.first_name} {fir.contact.last_name}</div>   
                        </div>
                    </Link>
                    ))}
                    
                </div>
                :
                <div></div>
            }       
            <div className={classes.header2}>
                <div>2nd Node</div>
                <div className={classes.top}>
                    <div>{second.length}</div>
                    <button className={classes.btn2} onClick={() => setToggle3(!toggle3)}>
                        <ArrowDropDownIcon />
                    </button>
                    </div>
            </div>
            { toggle3 ?
                <div className={classes.nodes_head}>
                    {second.map((sec) => (
                    <Link key={sec.id} to={`/node_profile/${sec.contact.id}/`} onClick={() => dispatch(editOther_user_id(sec.contact.id))}　style={{ textDecoration: 'none', color: '#424242' }}>
                        <div className={classes.nodes_box}>
                            <Avatar src={sec.contact.avatar_url} className={classes.avatar}/>
                            <div className={classes.avatar_name}>{sec.contact.first_name} {sec.contact.last_name}</div>   
                        </div>
                    </Link>
                    ))}
                </div>
                :
                <div></div>
            }
            <Footer />
        </div>
    )
}

export default withCookies(Node_list)






{/* <div>
            <div className={classes.header1}>
                <div>Ani Terada</div>
                <button className={classes.btn1} onClick={() => setToggle1(!toggle1)}>
                { toggle1 ?
                    <SearchIcon />
                    :
                    <CloseIcon />
                }
                </button>
            </div>
            { toggle1 ?
                <div>
                    <div className={classes.header2}>
                        <div>1st Node</div>
                        <div className={classes.top}>
                            <div>14</div>
                            <button className={classes.btn2} onClick={() => setToggle2(!toggle2)}>
                                <ArrowDropDownIcon />
                            </button>
                        </div>
                    </div>
                    { toggle2 ?
                        <div className={classes.nodes}>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                        </div>
                        :
                        <div></div>
                    } 
                    <div className={classes.header2}>
                        <div>2nd Node</div>
                        <div className={classes.top}>
                            <div>25</div>
                            <button className={classes.btn2} onClick={() => setToggle3(!toggle3)}>
                                <ArrowDropDownIcon />
                            </button>
                            </div>
                    </div>
                    { toggle3 ?
                        <div className={classes.nodes}>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                            <div className={classes.node_space}><Person /></div>
                        </div>
                        :
                        <div></div>
                    }


                </div>
                
                :
                <div>
                    <div className={classes.search_container}>
                        <input type='text' size="25" placeholder="キーワード検索"/>

                    </div>
                    <div className={classes.nodes2}>
                        <div className={classes.node_space2}><Person /></div>
                        <div className={classes.node_space2}><Person /></div>
                        <div className={classes.node_space2}><Person /></div>
                        <div className={classes.node_space2}><Person /></div>
                        <div className={classes.node_space2}><Person /></div>
                        <div className={classes.node_space2}><Person /></div>
                        <div className={classes.node_space2}><Person /></div>
                        <div className={classes.node_space2}><Person /></div>
                    </div>
                </div>
            }
            <Footer />
        </div> */}