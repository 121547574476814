import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = "https://www.axelnode-dashboard.com/";

export const fetchAsyncNoticeListGet = createAsyncThunk("notice_list/get", async (token) => {
    const res = await axios.get(`${apiUrl}api/v1/notifications/topic`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return res.data;
});

export const fetchAsyncNoticeSystemListGet = createAsyncThunk("notice_system_list/get", async (token) => {
  const res = await axios.get(`${apiUrl}api/v1/notifications/system/`, {
    headers: {
      Authorization: `Token ${token}`,
    },
  });
  return res.data;
});

export const fetchAsyncNoticeGet = createAsyncThunk("notice/get", async (data) => {
    const res = await axios.get(`${apiUrl}api/v1/notifications/topic/${data.news_id}`, {
      headers: {
        Authorization: `Token ${data.token}`,
      },
    });
    return res.data;
});

const noticesSlice = createSlice({
    name: 'notices',
    initialState: {
        notice_list: [],
        notice: {
            id: 0,
            topic: "",
            title: "", 
            description: "",
            status: "",
            creator: {first_name: "", last_name: "", avatar_url: null, has_access: true},
            planned_send_date: "", 
            modified: "", 
            created: ""
        },   
        notice_system_list: [],
    },
    reducers: {
        editNotice_id(state, action) {
            state.notice.id = action.payload;
        },
        
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncNoticeListGet.fulfilled, (state, action) => {
          state.notice_list = action.payload;
        });
        builder.addCase(fetchAsyncNoticeGet.fulfilled, (state, action) => {
            state.notice = action.payload;
        });
        builder.addCase(fetchAsyncNoticeSystemListGet.fulfilled, (state, action) => {
          state.notice_system_list = action.payload;
        });
    },
})

export const { editNotice_id } = noticesSlice.actions;

export const selectNotice_list = (state) => state.notices.notice_list;
export const selectNotice = (state) => state.notices.notice;
export const selectNoticeSystem_list = (state) => state.notices.notice_system_list;

export default noticesSlice.reducer;

