import React, { useEffect } from 'react';
import { withCookies } from 'react-cookie';
import classes from './Experience.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useSelector, useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';

import {
    editExp_position, 
    editExp_company,
    editExp_start, 
    editExp_end,
    editExp_current,
    selectProfile,
    selectExperience,
    fetchAsyncExperienceGet,
    fetchAsyncExperienceDelete,
    fetchAsyncExperiencePut,
} from "../profile/profile_editSlice";

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2),
      width: 150,
    },
}));

const Experience = (props) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const user_pro = useSelector(selectProfile);
    const experience = useSelector(selectExperience);

    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
      };
    const handleClose = () => {
    setOpen(false);
    };

    useEffect(() => {
        dispatch(fetchAsyncExperienceGet({'user_pro': user_pro, 'exp': experience, 'token': token}))
    }, [dispatch]);

    const delete_exp = async () => {
        await dispatch(fetchAsyncExperienceDelete({'user_pro': user_pro, 'exp': experience, 'token': token}))
    }

    const update = async () => {
        await dispatch(fetchAsyncExperiencePut({'user_pro': user_pro, 'exp': experience, 'token': token}))
    }

    return (
        <div>
            <div className={classes.header}>
                <Link to='/profile_edit/'>
                    <button className={classes.left}><ArrowBackIosIcon /></button>
                </Link>
                <span className={classes.center}>Experience Edit</span>
            </div>

            <div className={classes.head}>
                <div>Position</div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="text"
                        name="Email"
                        size="49"
                        placeholder='Developer, Product Manager, etc.'
                        value={experience.position}
                        onChange={(e) => dispatch(editExp_position(e.target.value))}
                    />
                </div>
            </div>

            <div className={classes.head}>
                <div>Company name</div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="text"
                        name="Email"
                        size="49"
                        placeholder='Google, Uber, etc.'
                        value={experience.ext_company}
                        onChange={(e) => dispatch(editExp_company(e.target.value))}
                    />
                </div>
            </div>

            <div className={classes.parent}>
                <div className={classes.child1}>
                    <div>Period</div>
                    <div>Start</div>
                    <div>
                        <TextField
                            id="date"
                            type="date"
                            className={styles.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            defaultValue={experience.start_date}
                            onChange={(e) => dispatch(editExp_start(e.target.value))}
                            value={experience.start_date}
                        />
                    </div>
                </div>
                <div className={classes.child2}>
                    <div className={classes.space}></div>
                    <div>End</div>
                    <div>
                        <TextField
                            id="date"
                            type="date"
                            className={styles.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            defaultValue={experience.end_date}
                            onChange={(e) => dispatch(editExp_end(e.target.value))}
                            value={experience.end_date}
                            disabled={experience.is_current}
                        />
                    </div>
                    <div className={classes.check}>
                        <Checkbox
                            value={experience.is_current}
                            onChange={() => dispatch(editExp_current(!experience.is_current))}
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            checked={experience.is_current}
                        />
                        <div>Current</div>
                    </div>
                </div>
            </div>

            <div>
                <div className={classes.btnpos}>
                    <button
                        variant="contained"
                        className={classes.tag1}
                        onClick={update}
                    >
                        Update
                    </button>
                </div>
                <div className={classes.btnpos}>
                    <button
                        variant="contained"
                        className={classes.tag2}
                        onClick={handleClickOpen}
                    >
                        Delete
                    </button>
                    <Dialog style={{textAlign: 'center'}}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="logout-dialog-title"
                        aria-describedby="logout-dialog-description"
                    >
                        <DialogTitle id="logout-dialog-title">{""}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="logout-dialog-description">
                            Are you sure you want to log out?
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleClose} color="primary" autoFocus onClick={delete_exp}>
                            Delete
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        </div>
    )
}

export default withCookies(Experience)
