import React, {useEffect} from 'react';
import { withCookies } from "react-cookie";
import classes from './Refer_oth.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Avatar from '@material-ui/core/Avatar';
import Footer from '../footer/Footer';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import {
    selectTicket,
    fetchAsyncReferFreePut,
    selectSubticket_treat,
    selectSubticket_uuid,
    editSubticket_message,
    selectChain,
} from "../open_ticket/open_ticketSlice";

const Refer_oth = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const subticket_treat = useSelector(selectSubticket_treat);
    const ticket = useSelector(selectTicket);
    const subticket_uuid = useSelector(selectSubticket_uuid);
    const chain = useSelector(selectChain);
    const refer_free = async () => {
        const res = await dispatch(fetchAsyncReferFreePut({'subticket_treat': subticket_treat, 'company_id': ticket.company.id, 'subticket_uuid': subticket_uuid, 'token': token}))
        props.cookies.set("after_ticek_id", res.payload.uuid, {maxAge: 3600, path: '/'});
    }

    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);


    useEffect(async() => {
        if (ticket.id === 0) {
            window.location.href = '/open_ticket';
        }
    }, [dispatch]);

    return (
        <div>
            <div>
                <div className={classes.head}>
                    <span className={classes.center}>Business Development</span>
                </div>
            </div>
            <div className={classes.top}>Add your message and refer</div>
            <div className={classes.set}>
                {chain.parent_user != null &&
                    <div className={classes.set}>
                        <div className={classes.first}></div>
                        <Avatar src={chain.parent_user.avatar_url}/>
                    </div>
                }
                <div className={classes.set}>
                    <div className={classes.second}></div>
                    <Avatar src={chain.user.avatar_url}/>
                </div>
                <div className={classes.set}>
                    <div className={classes.second}></div>
                    <Avatar />
                </div>
            </div>
            <textarea 
             className={classes.form} 
             placeholder='Message for refer' 
             name="refer" cols="40" rows="5"
             value={subticket_treat.message}
             onChange={(e) => dispatch(editSubticket_message(e.target.value))} 
             maxLength='1000'/>
            <button className={classes.str1} onClick={() => dispatch(editSubticket_message('I sent a special job offer because I think that you are the best person who has some skills on this thicket in my network. Please check it out!'))}>Apply a template</button>

            <div className={classes.footer}>
                <div className={classes.value}>Reward: 200 AXP</div>
                <button className={classes.app} onClick={refer_free}>Refer</button>
            </div>

            <Footer />
            
            
            

        </div>
    )
}

export default withCookies(Refer_oth)
