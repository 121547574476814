import React, { useEffect } from 'react';
import { withCookies } from 'react-cookie';
import classes from './Education.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import {
    editEdu_institution, 
    editEdu_dgree, 
    editEdu_field, 
    editEdu_start, 
    editEdu_end,
    selectProfile,
    selectEducation,
    fetchAsyncEducationPost,
} from "../profile/profile_editSlice";

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(2),
      width: 150,
    },
}));

const Education = (props) => {
    const [checked, setChecked] = React.useState(true);
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const user_pro = useSelector(selectProfile);
    const education = useSelector(selectEducation);
    const styles = useStyles();

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
      };


    const education_post = async () => {
        await dispatch(fetchAsyncEducationPost({'user_pro': user_pro, 'edu': education, 'token': token}))
    }

    useEffect(() => {
        dispatch(editEdu_institution(''))
        dispatch(editEdu_dgree(''))
        dispatch(editEdu_field(''))
        dispatch(editEdu_start(''))
        dispatch(editEdu_end(''))
    }, [dispatch]);

    return (
        <div>
            <div className={classes.header}>
                <Link to='/profile_edit/'>
                    <button className={classes.left}><ArrowBackIosIcon /></button>
                </Link>
                <span className={classes.center}>Education</span>
            </div>

            <div className={classes.head}>
                <div>School/University</div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="text"
                        name="School"
                        size="49"
                        placeholder='Harvard, Stanford, etc.'
                        onChange={(e) => dispatch(editEdu_institution(e.target.value))}
                    />
                </div>
            </div>

            <div className={classes.head}>
                <div>Degree</div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="text"
                        name="Degree"
                        size="49"
                        placeholder='Bachelor, Master, etc.'
                        onChange={(e) => dispatch(editEdu_dgree(e.target.value))}
                    />
                </div>
            </div>

            <div className={classes.head}>
                <div>Field of study</div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="text"
                        name="Field"
                        size="49"
                        placeholder='engineering, chemistry, etc.'
                        onChange={(e) => dispatch(editEdu_field(e.target.value))}
                    />
                </div>
            </div>

            <div className={classes.parent}>
                <div className={classes.child1}>
                    <div>Period</div>
                    <div>Start</div>
                    <form className={classes.container} noValidate>
                        <TextField
                            id="date"
                            type="date"
                            className={styles.textField}
                            InputLabelProps={{
                            shrink: true,
                            }}
                            onChange={(e) => dispatch(editEdu_start(e.target.value))}
                        />
                    </form>
                </div>
                <div className={classes.child2}>
                    <div className={classes.space}></div>
                    <div>End(expected)</div>
                    <div>
                        <form className={classes.container} noValidate>
                            <TextField
                                id="date"
                                type="date"
                                className={styles.textField}
                                InputLabelProps={{
                                shrink: true,
                                }}
                                onChange={(e) => dispatch(editEdu_end(e.target.value))}
                            />
                        </form>
                    </div>
                </div>
            </div>

            <div>
                <div className={classes.btnpos}>
                    <button
                        variant="contained"
                        className={classes.tag1}
                        onClick={education_post}
                    >
                        Save
                    </button>
                </div>
                <div className={classes.btnpos}>
                    <button
                        variant="contained"
                        className={classes.tag2}
                        onClick={handleClickOpen}
                    >
                        <Link to='/profile_edit/' style={{ textDecoration: 'none', color: '#424242' }}>
                            Cancel
                        </Link>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default withCookies(Education)
