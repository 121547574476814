import React, {useEffect} from 'react';
import { withCookies } from 'react-cookie';
import axios, { put } from 'axios';
import classes from './Upload.module.css';
import Button from '@material-ui/core/Button';

const apiUrl = "https://www.axelnode-dashboard.com/";

class SimpleReactFileUpload extends React.Component {
  

  constructor(props) {
    super(props);
    this.state ={
      file:null
    }
    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.fileUpload = this.fileUpload.bind(this)
    this.token = props.cookies.get("current-token");
  }

  onFormSubmit(e){
    e.preventDefault() // Stop form submit
    this.fileUpload(this.state.file).then((response)=>{
      console.log(response.data);
    })
  }

  onChange(e) {
    this.setState({file:e.target.files[0]})
  }

  fileUpload(file){
    
    const url = `${apiUrl}api/v1/users_profiles/${this.props.user_id}/avatar_upload/`;
    const formData = new FormData();
    formData.append('avatar_file',file)
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Token ${this.token}`,
        }
    }
    return  put(url, formData,config)
  }

  render() {
    return (
      <form onSubmit={this.onFormSubmit}>
        <div for="file_upload" className={classes.label}>
          <input 
          　type="file" 
            id="file_upload"
          　onChange={this.onChange}
            className={classes.input}
          />
        </div>
        <Button type="submit" onClick={this.props.avatar_close}>Upload</Button>
      </form>
   )
  }
}
export default withCookies(SimpleReactFileUpload)