import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = "https://www.axelnode-dashboard.com/";

export const fetchAsyncReset_pasPut = createAsyncThunk('reset/put', async (pas) => {
    const createData = new FormData();
    createData.append('password', pas.reset_pas)
    createData.append('token', pas.token)
    const res = await axios.put(`${apiUrl}api/v1/users/reset_password_by_token/`, createData, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
} 
)

const resetSlice = createSlice({
    name: 'reset_password',
    initialState: {
        reset_info: {
            reset_pas: '',
            token: '',
        },
    },
    reducers: {
        editReset_pas(state, action) {
            state.reset_info.reset_pas = action.payload;
        },
        editToken(state, action) {
            state.reset_info.token = action.payload;
        },
    },
})

export const { editReset_pas, editToken } = resetSlice.actions;
export const selectReset_pas = (state) => state.reset_password.reset_info;

export default resetSlice.reducer;


