import React, { useState, useEffect } from 'react';
import classes from './Open_ticket.module.css';
import Avatar from '@material-ui/core/Avatar';
import Ticket from './Ticket';
import Ticket2 from './Ticket2';
import Footer from '../footer/Footer';
import { withCookies } from "react-cookie";
import { Link } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';
import { useSelector, useDispatch } from "react-redux";

import {
    selectProfile,
    fetchAsyncUserProGet,
} from "../profile/profile_editSlice";

import {
    selectSubticket,
    fetchAsyncSubticketGet,
    editSubticket_uuid,
    selectView_toggle,
    editView_toggle,
} from "./open_ticketSlice";

const Open_ticket = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const user_pro = useSelector(selectProfile);
    const subticket = useSelector(selectSubticket);
    const toggle = useSelector(selectView_toggle);


    const filter_my_ticket = subticket.results.filter((output) => {
        return output.status == "new_assigned";
    })

    const filter_activated = subticket.results.filter((output) => {
        return output.status != "new_assigned" && output.status != "new";
    })

    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);
    
    useEffect(() => {
        dispatch(fetchAsyncUserProGet(token))
        dispatch(fetchAsyncSubticketGet(token))
    }, [dispatch]);

    return (
        <div className={classes.body}>
            <header className={classes.back}>
                <div className={classes.tag1}>
                    <div className={classes.img}>
                    <Link to='/profile_edit/' style={{ textDecoration: 'none', color: '#424242' }}>
                        <Avatar src={user_pro.avatar_url}/>
                    </Link>
                    </div>
                    <ul className={classes.list}>
                        <li className={classes.str1}>{user_pro.first_name} {user_pro.last_name}</li>
                        <li className={classes.str2}>{user_pro.email}</li>
                        <li className={classes.str2}>{user_pro.company}</li>
                    </ul>
                    <Link to='/setting/'>
                        <SettingsIcon className={classes.setting_icon}/>
                    </Link>
                </div>
                <div className={classes.tag2}>
                    <div className={toggle ? classes.str3_under : classes.str3} onClick={() => dispatch(editView_toggle(true))}>MY TICKETS</div>
                    <div className={toggle ? classes.str3 : classes.str3_under} onClick={() => dispatch(editView_toggle(false))}>ACTIVATED</div>
                </div>
            </header>
            <h3 className={classes.note}>ALL TICKETS</h3>

            { toggle ?
                <div>
                    {filter_my_ticket.map((sub) => (
                        <Link 
                         key={sub.uuid}
                         id={sub.ticket.id}
                         to={`/subticket/${sub.ticket.id}/${sub.uuid}/`}
                         style={{ textDecoration: 'none', color: '#424242' }} 
                         onClick={() => {
                            dispatch(editSubticket_uuid(sub.uuid))
                         }}
                        >
                            <Ticket key={sub.uuid} sub={sub}/>
                        </Link>
                    ))}
                    {filter_my_ticket.length === 0 && 
                        <div style={{textAlign: 'center', marginTop: '200px', color: '#929292'}}>   
                            No tickets
                        </div>
                    }
                </div>
                :
                <div>
                    {filter_activated.map((sub) => (
                        <Link 
                         key={sub.uuid}
                         id={sub.ticket.id}
                         to={`/subticket2/${sub.ticket.id}/${sub.uuid}/`}
                         style={{ textDecoration: 'none', color: '#424242' }} 
                         onClick={() => {
                            dispatch(editSubticket_uuid(sub.uuid))
                         }}
                        >
                            <Ticket2 key={sub.uuid} sub={sub}/>
                        </Link>
                    ))}
                    {filter_activated.length === 0 && 
                        <div style={{textAlign: 'center', marginTop: '200px', color: '#929292'}}>   
                            No tickets
                        </div>
                    }
                </div>

            }

        
            <Footer />


        </div>
    )
}

export default withCookies(Open_ticket);
