import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = "https://www.axelnode-dashboard.com/";

export const fetchAsyncEmailPut = createAsyncThunk('email/put', async (email) => {
    const createData = new FormData();
    createData.append('email', email)
    const res = await axios.put(`${apiUrl}api/v1/users/password_recovery/`, createData, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
} 
)

const emailSlice = createSlice({
    name: 'email',
    initialState: {
        email: '',
    },
    reducers: {
        editEmail(state, action) {
            state.email = action.payload;
        },
    },
})

export const { editEmail } = emailSlice.actions;
export const selectEmail = (state) => state.email.email;

export default emailSlice.reducer;


