import React, {useState, useEffect} from 'react';
import Icon from './Vector 1.png';
import classes from './Setting.module.css';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { withCookies } from 'react-cookie'

const Setting = (props) => {
    const Logout = async() => {
        props.cookies.remove('current-token', {path: '/'});
        props.cookies.remove('user_id', {path: '/'});
        window.location.href = '/';
    }
    const [open1, setOpen1] = useState(false);
    const handleClickOpen1 = () => {
        setOpen1(true);
      };
    const handleClose1 = () => {
    setOpen1(false);
    };

    // const [open2, setOpen2] = useState(false);
    // const handleClickOpen2 = () => {
    //     setOpen2(true);
    //   };
    // const handleClose2 = () => {
    // setOpen2(false);
    // };

    return (
        <div>
            <div className={classes.head}>
                <Link to='/open_ticket/'>
                    <button className={classes.left}><ArrowBackIosIcon /></button>
                </Link>
                <span className={classes.center}>How to use</span>
            </div>

            <div className={classes.box}>
                <span className={classes.srt}>How to use</span>
                <Link to='/How1/'>
                    <img className={classes.img} src={Icon} />
                </Link>
            </div>
            <div className={classes.space}></div>

            {/* <div className={classes.box}>
                <span className={classes.srt}>Wallet</span>
                <img className={classes.img} src={Icon} />
            </div>
            <div className={classes.space}></div> */}

            <div className={classes.box}>
                <span className={classes.srt}>Notification settings</span>
                <Link to='/notification/'>
                    <img className={classes.img} src={Icon} />
                </Link>
            </div>
            <div className={classes.space}></div>

            <div className={classes.box}>
                <span className={classes.srt}>Change password</span>
                <Link to='/change/'>
                    <img className={classes.img} src={Icon} />
                </Link>
            </div>
            <hr className={classes.length}/>

            <div className={classes.box}>
                <span className={classes.srt}>FAQ</span>
                <img className={classes.img} src={Icon} />
            </div>
            <hr className={classes.length}/>

            {/* <div className={classes.box}>
                <span className={classes.srt}>Feedback</span>
                <img className={classes.img} src={Icon} />
            </div>
            <hr className={classes.length}/> */}

            <div className={classes.box}>
                <span className={classes.srt}>Privacy policy</span>
                <img className={classes.img} src={Icon} />
            </div>
            <hr className={classes.length}/>

            <div className={classes.box}>
                <span className={classes.srt}>Terms of use</span>
                <img className={classes.img} src={Icon} />
            </div>
            <div className={classes.space}></div>

            {/* <div className={classes.box}>
                <span className={classes.srt1}>Login like a company</span>
                <img className={classes.img} src={Icon} />
            </div>
            <div className={classes.space}></div> */}
            
            <div>
                <div className={classes.box}>
                    <span className={classes.srt}>Log out</span>
                    <img className={classes.img} src={Icon} onClick={handleClickOpen1}/>
                </div>
                <Dialog style={{textAlign: 'center'}}
                    open={open1}
                    onClose={handleClose1}
                    aria-labelledby="logout-dialog-title"
                    aria-describedby="logout-dialog-description"
                >
                    <DialogTitle id="logout-dialog-title">{""}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="logout-dialog-description">
                        Are you sure you want to log out?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose1} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => Logout()} color="primary" autoFocus>
                        Log out
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
            <hr className={classes.length}/>

            {/* <div>
                <div className={classes.box}>
                    <span className={classes.srt}　onClick={handleClickOpen2}>Delete account</span>
                    <img className={classes.img} src={Icon} />
                </div>
                <Dialog style={{textAlign: 'center'}}
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby="delete-dialog-title"
                    aria-describedby="delete-dialog-description"
                >
                    <DialogTitle id="delete-dialog-title">{"Are you sure you want to delete account?"}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="delete-dialog-description">
                        After deleting your account all data cannot be restored.
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <button onClick={handleClose2} color="primary">
                        Cancel
                    </button>
                    <button onClick={handleClose2} color="primary" autoFocus>
                        Delete
                    </button>
                    </DialogActions>
                </Dialog>
            </div>
            <hr className={classes.length}/> */}

            <div className={classes.box}>
                <span className={classes.srt}>Version 1.0</span>
                <img className={classes.img} src={Icon} />
            </div>

            
        </div>
    )
}

export default withCookies(Setting)
