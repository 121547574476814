import React, { useState, useEffect } from 'react';
import { withCookies } from "react-cookie";
import Icon from './AXN_Logo_Horizontal.png';
import classes from './CreateTwo.module.css';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

import {
    editEmail,
    editPassword,
    fetchAsyncRegister,
    selectAuthen,
  } from "./createtwoSlice";

const CreateTwo = (props) => {
    const dispatch = useDispatch();
    const authen = useSelector(selectAuthen);
    const [confirm, setConfirm] = useState('')
    const [success_modal, setSuccess_modal] = useState(false)
    const [failure_modal, setFailure_modal] = useState(false)
    const btnDisabler1 = authen.email === "" || authen.password === "";
    const btnDisabler2 = !(confirm === authen.password)

    const successClose = () => {
        setSuccess_modal(false);
    };
    const failureClose = () => {
        setFailure_modal(false);
    };

    const register = async () => {
        const result = await dispatch(fetchAsyncRegister(authen))

        if (fetchAsyncRegister.fulfilled.match(result)) {
            setSuccess_modal(true);
        } else {
            setFailure_modal(true);
        }
    }

    useEffect(() => {
        if (!authen.first_name) {
            window.location.href = '/createOne/';
        }
    }, [authen.first_name]);

    return (
        <div className={classes.head}>
            <div>
                <img className={classes.img} src={Icon}/>
            </div>
            <div>
                <div className={classes.form}>
                    <input className={classes.input}
                        type="email"
                        name="Email"
                        placeholder="Email"
                        onChange={(e) => dispatch(editEmail(e.target.value))}
                        required
                    />
                </div>
                <hr className={classes.hr}/>
                <div className={classes.form}>
                    <input className={classes.input}
                        type="password"
                        name="Password"
                        placeholder="Password"
                        onChange={(e) => dispatch(editPassword(e.target.value))}
                        required
                    />
                </div>
                <hr className={classes.hr}/>
                <div className={classes.form}>
                    <input className={classes.input}
                        type="password"
                        name="Password_again"
                        placeholder="Password again"
                        onChange={(e) => setConfirm(e.target.value)}
                        required
                    />
                </div>
                <hr className={classes.hr}/>

                <div className={classes.note}>
                    {btnDisabler2 &&
                        <h2 className={classes.note1}>* Password does not match</h2>
                    }
                </div>

                <div>
                    <button
                        variant="contained"
                        className={classes.create_btn}
                        color="primary"
                        disabled={btnDisabler1 || btnDisabler2}
                        onClick={register}
                    >
                        Create an account
                    </button>
                    <Dialog style={{textAlign: 'center'}}
                        open={success_modal}
                        onClose={successClose}
                        aria-labelledby="logout-dialog-title"
                        aria-describedby="logout-dialog-description"
                    >
                        <DialogTitle id="logout-dialog-title">{"Success"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="logout-dialog-description">
                            <div style={{color: 'blue', fontWeight: 'bold'}}>Account registerd successfully.</div>
                            <div style={{color: 'blue', fontWeight: 'bold'}}>Confirmation email has been sent. Please check your inbox.</div>
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={successClose} color="primary">
                            <Link to='/login/'  style={{ textDecoration: 'none', color: '#424242' }}>Login</Link>
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog style={{textAlign: 'center'}}
                        open={failure_modal}
                        onClose={failureClose}
                        aria-labelledby="logout-dialog-title"
                        aria-describedby="logout-dialog-description"
                    >
                        <DialogTitle id="logout-dialog-title">{"Error"}</DialogTitle>
                        <DialogContent>
                        <DialogContentText id="logout-dialog-description">
                            Something was wrong, try again. <br/>
                            ・Password should be not less than 6 characters, one lowercase, one uppercase letter and one number.<br/>
                            ・cannot register the same email address
                        </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                        <Button onClick={failureClose} color="primary">
                            OK
                        </Button>
                        </DialogActions>
                    </Dialog>
                    <br/>
                    <button className={classes.have_account}>
                        <Link to='/login/' style={{ textDecoration: 'none', color: '#424242' }}> Already have an account?</Link>
                    </button>
                </div>
                
            </div>
            
            
            
        </div>
    )
}

export default withCookies(CreateTwo)
