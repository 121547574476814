import React, {useEffect} from 'react';
import { withCookies } from "react-cookie";
import classes from './Burn.module.css';
import Avatar from '@material-ui/core/Avatar';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Checkbox from '@material-ui/core/Checkbox';
import { useSelector, useDispatch } from "react-redux";

import {
    selectTicket,
    selectChain,
    fetchAsyncBurnPut,
    selectSubticket_treat,
    selectSubticket_uuid,
} from "../open_ticket/open_ticketSlice";

const Burn = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const ticket = useSelector(selectTicket);
    const subticket_treat = useSelector(selectSubticket_treat);
    const subticket_uuid = useSelector(selectSubticket_uuid);
    const chain = useSelector(selectChain);

    const burn = async () => {
        const res = await dispatch(fetchAsyncBurnPut({'subticket_treat': subticket_treat, 'company_id': ticket.company.id, 'subticket_uuid': subticket_uuid, 'token': token}))
        props.cookies.set("after_ticek_id", res.payload.uuid, {maxAge: 3600, path: '/'});
    }

    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);

    useEffect(async() => {
        if (ticket.id === 0) {
            window.location.href = '/open_ticket/';
        }
    }, [dispatch]);
    
    return (
        <div>
            <div>
                <div className={classes.header}>
                    <span className={classes.center}>Business Development</span>
                </div>
            </div>
            <div className={classes.top}>Don’t you have any idea?</div>
            <div className={classes.set}>
                {chain.parent_parent_user != null &&
                    <div className={classes.set}>
                        <div className={classes.first}></div>
                        <Avatar src={chain.parent_parent_user.avatar_url}/>
                    </div>
                }
                {chain.parent_user != null &&
                    <div className={classes.set}>
                        <div className={classes.second}></div>
                        <Avatar src={chain.parent_user.avatar_url}/>
                    </div>
                }
                <div className={classes.set}>
                    <div className={classes.second}></div>
                    <Avatar src={chain.user.avatar_url}/>
                </div>
            </div>
            <div className={classes.note}>This ticket gets back to the publisher if you burn it.</div>

            <div className={classes.head}>
                <div className={classes.inhead}>
                    <CheckCircleOutlineIcon className={classes.icon1}/>
                    <div className={classes.str1}>
                        Sure you don't have a lead for this position? If you burn this ticket, it will be returned to the publisher.
                        Once burned, the ticket CANNOT be restored. 
                    </div>
                </div>
                <button className={classes.button1}>
                    <Checkbox className={classes.icon2}/>
                </button>
            </div>

            <div className={classes.footer}>
                <div className={classes.value}>Reward: 10 AXP</div>
                <button className={classes.app} onClick={burn}>Burn</button>
            </div>


        </div>
    )
}

export default withCookies(Burn)
