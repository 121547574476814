import React, {useEffect} from 'react';
import { withCookies } from "react-cookie";
import classes from './Apply.module.css';
import Avatar from '@material-ui/core/Avatar';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import {
    selectTicket,
    selectChain,
    fetchAsyncApplyPut,
    selectSubticket_treat,
    selectSubticket_uuid,
} from "../open_ticket/open_ticketSlice";

const Apply = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const subticket_treat = useSelector(selectSubticket_treat);
    const ticket = useSelector(selectTicket);
    const subticket_uuid = useSelector(selectSubticket_uuid);
    const chain = useSelector(selectChain);

    const apply = async () => {
        const res = await dispatch(fetchAsyncApplyPut({'subticket_treat': subticket_treat, 'company_id': ticket.company.id, 'subticket_uuid': subticket_uuid, 'token': token}))
        props.cookies.set("after_ticek_id", res.payload.uuid, {maxAge: 3600, path: '/'});
    }

    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);

    useEffect(async() => {
        if (ticket.id === 0) {
            window.location.href = '/open_ticket';
        }
    }, [dispatch]);

    return (
        <div>
            <div className={classes.head}>
                <span className={classes.center}>Business Development</span>
            </div>
            <div className={classes.top}>Comfirm followings and apply</div>
            <div className={classes.set}>
                {chain.parent_parent_user != null &&
                    <div className={classes.set}>
                        <div className={classes.first}></div>
                        <Avatar src={chain.parent_parent_user.avatar_url}/>
                    </div>
                }
                {chain.parent_user != null &&
                    <div className={classes.set}>
                        <div className={classes.second}></div>
                        <Avatar src={chain.parent_user.avatar_url}/>
                    </div>
                }
                <div className={classes.set}>
                    <div className={classes.second}></div>
                    <Avatar src={chain.user.avatar_url}/>
                </div>
            </div>
            <div className={classes.smalltop}>You can apply after your comfirmation.</div>
            
            <div className={classes.head1}>
                <div className={classes.inhead}>
                    <ErrorOutlineIcon className={classes.icon1}/>
                    <div className={classes.str1}>You have to complete your profile.</div>
                </div>
                <Link to='/profile_edit/' className={classes.button1}>
                    <ChevronRightIcon  className={classes.icon2}/>
                </Link>
            </div>

            <div className={classes.head2}>
                <div className={classes.inhead}>
                    <CheckCircleOutlineIcon className={classes.icon1}/>
                    <div className={classes.str1}>We may ask questions about your skills to the person who referred you.</div>
                </div>
                <button className={classes.button1}>
                    <Checkbox className={classes.icon2}/>
                </button>
            </div>

            <div className={classes.footer}>
                <div className={classes.value}>Reward: 500 AXP</div>
                <button className={classes.app} onClick={apply}>APPLY</button>
            </div>
        </div>
    )
}

export default withCookies(Apply)
