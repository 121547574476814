import React, {useEffect} from 'react';
import { withCookies } from "react-cookie";
import classes from './Refer_oth_comp.module.css';
import Avatar from '@material-ui/core/Avatar';
import Ticket2 from './Ticket2';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import {
    selectAfter_ticekt,
    fetchAsyncSubticket_afterGet,
} from "../open_ticket/open_ticketSlice";

const Refer_comp = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const after_ticek_id = props.cookies.get("after_ticek_id");
    const after_ticket = useSelector(selectAfter_ticekt);

    useEffect(() => {
        dispatch(fetchAsyncSubticket_afterGet({'token': token, 'uuid': after_ticek_id}))
    }, [dispatch]);

    return (
        <div>
            <div　className={classes.header}>
                <div className={classes.instr}>Send the sentence below to your friend!</div>
            </div>
            <div className={classes.head}>
                <div>Don't forget!</div>
                <div className={classes.str}>Please send the sentence below to your friend!</div>
            </div>
            <div>{after_ticket.message}</div>
            <div className={classes.link}>
                Please create your account on this page 
                <br/>
                <br/>
                https://axelnodeweb.axelnode.com/sub_ticket/
                <br/>
                <br/>
                If you already have your account, Just fill the code and confirm.
                <br/>
                If you don't have, please create your account and fill out this invitation code
                <br/>
                <br/>
                Code: {after_ticket.child_link.slice(-16)}
            </div>
            <Ticket2 after_ticket={after_ticket}/>
            <Link to='/open_ticket/' style={{ textDecoration: 'none', color: '#424242' }}>
                <div className={classes.iconhead}>
                    <ExpandMoreIcon className={classes.icon} />
                </div>
            </Link>
            
            
        </div>
    )
}

export default withCookies(Refer_comp)
