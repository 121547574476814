import React, { useEffect } from 'react';
import { withCookies } from "react-cookie";
import classes from './Node_Detail.module.css';
import Avatar from '@material-ui/core/Avatar';
import Icon from './Vector.png';
import Icon1 from './Vector1.png';
import { useSelector, useDispatch } from "react-redux";

import {
    selectCompany,
    fetchAsyncCompanyGet,
    selectCompanypic,
    fetchAsyncCompanypicGet,
} from "../open_ticket/open_ticketSlice";

const Node_Detail = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const company = useSelector(selectCompany);
    const company_pic = useSelector(selectCompanypic);

    const other_id = (number) => {
        let dir = window.location.href.split("/");
        let dir2 = dir[dir.length - number];
        return dir2;
    }
    
    useEffect(() => {
        dispatch(fetchAsyncCompanyGet({'company_id': other_id(2), 'token': token}))
        dispatch(fetchAsyncCompanypicGet({'company_id': other_id(2), 'token': token}))
    }, [dispatch]);

    return (
        <div>
            <img className={classes.img} src={!(company_pic.length === 0) ? company_pic.picture : ''} />
            <div className={classes.pos}>
                <Avatar className={classes.ava} src={company.logo_url}/>
                <ul　className={classes.ten}>
                    <li className={classes.str1}>{company.name}</li>
                    <li className={classes.pos1}>
                        <img className={classes.img1} src={Icon1}/>
                        <div className={classes.str2}>{company.office_location}</div>
                    </li>
                    <li className={classes.pos1}>
                        <img className={classes.img1} src={Icon}/>
                        <div  className={classes.str2}>{company.company_size}</div>
                    </li>
                </ul>
            </div>
            <div className={classes.str}>
                {company.description.slice(264).slice(0, -32)}
            </div>
            <div className={classes.btnpos}>
                <button
                    variant="contained"
                    className={classes.tag}
                >
                    <a href={company.homepage_link} style={{ textDecoration: 'none', color: '#424242' }} target="_blank">Website</a>
                </button>
            </div>
        </div>
    )
}

export default withCookies(Node_Detail)
