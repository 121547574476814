import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Authentication from './features/authentication_screen/Authentication';
import Login from './features/login/Login';
import CreateOne from './features/createone/CreateOne';
import CreateTwo from './features/createtwo/CreateTwo';
import ForgotPas from './features/forgotpas/ForgotPas';
import Invitation from './features/invitation/Invitation';
import How1 from './features/how1/How1';
import How2 from './features/how2/How2';
import How3 from './features/how3/How3';
import How4 from './features/how4/How4';
import How5 from './features/how5/How5';
import How6 from './features/how6/How6';
import How7 from './features/how7/How7';
import Setting from './features/setting/Setting';
import Notification from './features/notification/Notification';
import Open_ticket from './features/open_ticket/Open_ticket';
import Subticket from './features/subticket/Subticket';
import Subticket2 from './features/subticket/Subticket2';
import Node_Detail from './features/node_detail/Node_Detail';
import Apply from './features/apply/Apply';
import Refer from './features/refer/Refer';
import Refer_ax from './features/refer_ax/Refer_ax';
import Refer_oth from './features/refer_oth/Refer_oth';
import Burn from './features/burn/Burn';
import Apply_comp from './features/apply_comp/Apply_comp';
import Refer_comp from './features/refer_comp/Refer_comp';
import Refer_oth_comp from './features/refer_oth_comp/Refer_oth_comp';
import Burn_comp from './features/burn_comp/Burn_comp';
import Node_list from './features/node_list/Node_list';
import Notices from './features/notices/Notices';
import News from './features/notices/News';
import Profile from './features/profile/Profile';
import Profile_edit from './features/profile/Profile_edit';
import Experience from './features/experience/Experience';
import Experience_edit from './features/experience/Experience_edit';
import Education from './features/experience/Education';
import Education_edit from './features/experience/Education_edit';
import Node_profile from './features/node_list/Node_profile';
import Activate from './features/activate/Activate';
import Change from './features/change/Change';
import Reset_pas from './features/reset_pas/Reset_pas';
import { CookiesProvider } from 'react-cookie';


function App() {
  return (
    <div className='App'>
      <CookiesProvider>
        <Router>
          <Route exact path='/auth/reset_password/*/' component={Reset_pas}/>
          <Route exact path='/change/' component={Change}/>
          <Route exact path='/user_activation/*/' component={Activate}/>
          <Route exact path='/' component={Authentication}/>
          <Route exact path='/login/' component={Login}/>
          <Route exact path='/createOne/' component={CreateOne}/>
          <Route exact path='/createTwo/' component={CreateTwo}/>
          <Route exact path='/forgotPas/' component={ForgotPas}/>
          <Route exact path='/sub_ticket/*/' component={Invitation}/>
          <Route exact path='/open_ticket/' component={Open_ticket}/>
          <Route exact path='/subticket/*/' component={Subticket}/>
          <Route exact path='/subticket2/*/' component={Subticket2}/>
          <Route exact path='/node_Detail/*/' component={Node_Detail}/>
          <Route exact path='/apply/' component={Apply}/>
          <Route exact path='/refer/' component={Refer}/>
          <Route exact path='/refer_ax/' component={Refer_ax}/>
          <Route exact path='/refer_oth/' component={Refer_oth}/>
          <Route exact path='/burn/' component={Burn}/>
          <Route exact path='/apply_comp/' component={Apply_comp}/>
          <Route exact path='/refer_oth_comp/' component={Refer_oth_comp}/>
          <Route exact path='/refer_comp/' component={Refer_comp}/>
          <Route exact path='/burn_comp/' component={Burn_comp}/>
          <Route exact path='/node_list/' component={Node_list}/>
          <Route exact path='/node_profile/*/' component={Node_profile}/>
          <Route exact path='/notices/' component={Notices}/>
          <Route exact path='/news/*/' component={News}/>
          <Route exact path='/profile/' component={Profile}/>
          <Route exact path='/profile_edit/' component={Profile_edit}/>
          <Route exact path='/experience/' component={Experience}/>
          <Route exact path='/experience_edit/' component={Experience_edit}/>
          <Route exact path='/education/' component={Education}/>
          <Route exact path='/education_edit/' component={Education_edit}/>
          <Route exact path='/how1/' component={How1}/>
          <Route exact path='/how2/' component={How2}/>
          <Route exact path='/how3/' component={How3}/>
          <Route exact path='/how4/' component={How4}/>
          <Route exact path='/how5/' component={How5}/>
          <Route exact path='/how6/' component={How6}/>
          <Route exact path='/how7/' component={How7}/>
          <Route exact path='/setting/' component={Setting}/>
          <Route exact path='/notification/' component={Notification}/>
        </Router>
      </CookiesProvider>
    </div>
  );
}

export default App;