import React, { useEffect, useState } from 'react';
import { withCookies } from "react-cookie";
import classes from './Change.module.css';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSelector, useDispatch } from "react-redux";
import Button from '@material-ui/core/Button';

import {
    editOld_pas, 
    editNew_pas,
    fetchAsyncPasswordPut,
    selectNew_pas,
    selectError,
} from "./changeSlice";

const Change = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const New_pas = useSelector(selectNew_pas);
    const error = useSelector(selectError);
    const [repeat, setRepeat] = useState('')
    const btnDisabler = !(repeat === New_pas.new_pas)
    const [open, setOpen] = useState(false);

    const close = () => {
        setOpen(false)
    }

    const change_pas = async () => {
        await dispatch(fetchAsyncPasswordPut({'pas': New_pas, 'token': token}))
        setOpen(false)
    }

    return (
        <div>
            <div className={classes.header}>
                <Link to='/setting/'>
                    <button className={classes.left}><ArrowBackIosIcon /></button>
                </Link>
                <span className={classes.center}>Change Password</span>
            </div>

            <div className={classes.head}>
                <div>Old Password</div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="password"
                        name="Old"
                        size="49"
                        onChange={(e) => dispatch(editOld_pas(e.target.value))}
                    />
                </div>
            </div>

            <div className={classes.head}>
                <div>New Password</div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="password"
                        name="New"
                        size="49"
                        onChange={(e) => dispatch(editNew_pas(e.target.value))}
                    />
                </div>
            </div>

            <div className={classes.head}>
                <div>Repeat New Password
                </div>
                <div className={classes.cp_iptxt1}>
                    <input className={classes.input}
                        type="password"
                        name="Repeat"
                        size="49"
                        onChange={(e) => setRepeat(e.target.value)}
                    />
                </div>
            </div>
            <div style={{color: 'red', margin: '10px'}}>
                {error.slice(0, 15)}
            </div>
            <div>
                <div className={classes.btnpos}>
                    <button
                        variant="contained"
                        className={classes.tag1}
                        onClick={() => setOpen(true)}
                        disabled={btnDisabler}
                    >
                        Save
                    </button>
                </div>
                <Dialog style={{textAlign: 'center'}}
                    open={open}
                    onClose={close}
                    aria-labelledby="logout-dialog-title"
                    aria-describedby="logout-dialog-description"
                >
                    <DialogTitle id="logout-dialog-title">{""}</DialogTitle>
                    <DialogContent>
                    <DialogContentText id="logout-dialog-description">
                        Are you sure you want to change?
                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={close} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={change_pas} color="primary" autoFocus>
                        OK
                    </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    )
}

export default withCookies(Change)
