import React, {useState, useEffect} from 'react'
import { withCookies } from "react-cookie";
import Ticket3 from './Ticket3';
import classes from './Subticket.module.css';
import Require from './Require';
import Conditions from './Conditions';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Avatar } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useSelector, useDispatch } from "react-redux";

import {
    selectTicket,
    fetchAsyncTicketGet,
    fetchAsyncChainGet,
    selectChain,
    selectAfter_ticekt,
    fetchAsyncSubticket_afterGet,
} from "../open_ticket/open_ticketSlice";

import {
    selectOther_info,
    fetchAsyncOther_userGet,
} from "../node_list/node_profileSlice";

const Subticket = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const [toggle1, setToggle1] = useState(true);
    const [toggle2, setToggle2] = useState(true);
    const ticket = useSelector(selectTicket);
    const after_subticket = useSelector(selectAfter_ticekt);
    const chain = useSelector(selectChain);
    const other_user = useSelector(selectOther_info);

    const ticket_id = (number) => {
        let dir = window.location.href.split("/");
        let dir2 = dir[dir.length - number];
        return dir2;
    }


    useEffect(() => {
        if (!token) {
            window.location.href = '/login/';
        }
    }, [token]);

    useEffect(async() => {
        dispatch(fetchAsyncTicketGet({'subticket_id': ticket_id(3), 'token': token}))
        dispatch(fetchAsyncChainGet({'uuid': ticket_id(2), 'token': token}))
        dispatch(fetchAsyncSubticket_afterGet({'uuid': ticket_id(2), 'token': token}))
    }, [dispatch]);

    useEffect(async() => {
        dispatch(fetchAsyncOther_userGet({'company_id': chain.company.owner, 'token': token}))
    }, [chain]);


    return (
        <div>
            <div className={classes.head}>
                <Link to='/open_ticket/'>
                    <button className={classes.left}><ArrowBackIosIcon /></button>
                </Link>
                <span className={classes.center}>Business Development</span>
            </div>
            <Ticket3 ticket={ticket}/>
            {after_subticket.message != '' &&
                <div className={classes.hukidasi}>
                    <span>
                    {after_subticket.message}
                    </span>
                </div>
            }
            <Require className={classes.re} ticket={ticket}/>
            <Conditions ticket={ticket}/>
            {/* <Link to={`/node_detail/${ticket.company.id}/`} style={{ textDecoration: 'none', color: '#424242' }}>
                <Publisher ticket={ticket}/>
            </Link> */}
            <div>
                <div className={classes.pos}>
                    <div className={classes.sikiri}>Ticket Detail</div>
                    <button　className={classes.triangle} onClick={() => setToggle1(!toggle1)}><ArrowDropDownIcon /></button>
                </div>
                <div>
                    { toggle1 ?
                        <div className={classes.detail_box}>
                            {ticket.description.slice(264, -32)}
                        </div>
                    :
                        <div></div>
                    }
                </div>
            </div>
            <div>
                <div className={classes.pos}>
                    <div className={classes.sikiri}>Referrence Chain</div>
                    <button　className={classes.triangle} onClick={() => setToggle2(!toggle2)}><ArrowDropDownIcon /></button>
                </div>
                <div>
                { toggle2 ?
                        <div>
                            <div className={classes.company1}>
                                <div className={classes.list}>
                                    <Avatar className={classes.avatar} src={chain.company.logo_url}/>
                                    <div>
                                        <div className={classes.str_company}>{chain.company.name}</div>
                                    </div>
                                </div>
                                <Link to={`/node_detail/${ticket.company.id}/`} style={{ textDecoration: 'none', color: '#424242' }}>
                                    <ArrowForwardIosIcon className={classes.icon}/>
                                </Link>
                            </div>
                            {other_user.id != 0 &&
                            <Link to={`/node_profile/${other_user.id}/`}　style={{ textDecoration: 'none', color: '#424242' }}>
                                <div className={classes.company2}>
                                    <div className={classes.list}>
                                        <Avatar className={classes.avatar} src={other_user.avatar_url}/>
                                        <div>
                                            <div className={classes.str1}>{other_user.first_name} {other_user.last_name}</div>
                                            <div className={classes.str2}>{other_user.position}</div>
                                            <div className={classes.str2}>{other_user.company_name}</div>
                                        </div>
                                    </div>
                                    <ArrowForwardIosIcon className={classes.icon}/>
                                </div>
                            </Link>
                            }
                            {chain.parent_parent_user != null &&
                                <div>
                                    <div　className={classes.v_line_fix}></div>
                                    <Link to={`/node_profile/${chain.parent_parent_user.id}/`}　style={{ textDecoration: 'none', color: '#424242' }}>
                                    <div className={classes.company2}>
                                        <div className={classes.list}>
                                            <Avatar className={classes.avatar} src={chain.parent_parent_user.avatar_url}/>
                                            <div>
                                                <div className={classes.str1}>{chain.parent_parent_user.first_name} {chain.parent_user.last_name}</div>
                                                <div className={classes.str2}>{chain.parent_parent_user.position}</div>
                                                <div className={classes.str2}>{chain.parent_parent_user.company_name}</div>
                                            </div>
                                        </div>
                                        <ArrowForwardIosIcon className={classes.icon}/>
                                    </div>
                                    </Link>
                                </div>
                            }
                            {chain.parent_user != null &&
                                <div>
                                    <div　className={classes.v_line_fix}></div>
                                    <Link to={`/node_profile/${chain.parent_user.id}/`}　style={{ textDecoration: 'none', color: '#424242' }}>
                                    <div className={classes.company2}>
                                        <div className={classes.list}>
                                            <Avatar className={classes.avatar} src={chain.parent_user.avatar_url}/>
                                            <div>
                                                <div className={classes.str1}>{chain.parent_user.first_name} {chain.parent_user.last_name}</div>
                                                <div className={classes.str2}>{chain.parent_user.position}</div>
                                                <div className={classes.str2}>{chain.parent_user.company_name}</div>
                                            </div>
                                        </div>
                                        <ArrowForwardIosIcon className={classes.icon}/>
                                    </div>
                                    </Link>
                                </div>
                            }
                            <div>
                                <div　className={classes.v_line_fix}></div>
                                <Link to={`/node_profile/${chain.user.id}/`}　style={{ textDecoration: 'none', color: '#424242' }}>
                                <div className={classes.company2}>
                                    <div className={classes.list}>
                                        <Avatar className={classes.avatar} src={chain.user.avatar_url}/>
                                        <div>
                                            <div className={classes.str1}>{chain.user.first_name} {chain.user.last_name}</div>
                                            <div className={classes.str2}>{chain.user.position}</div>
                                            <div className={classes.str2}>{chain.user.company_name}</div>
                                        </div>
                                    </div>
                                    <ArrowForwardIosIcon className={classes.icon}/>
                                </div>
                                </Link>
                            </div>
                            {chain.child_user != null &&
                                <div>
                                    <div　className={classes.v_line_fix}></div>
                                    <Link to={`/node_profile/${chain.child_user.id}/`}　style={{ textDecoration: 'none', color: '#424242' }}>
                                    <div className={classes.company2}>
                                        <div className={classes.list}>
                                            <Avatar className={classes.avatar} src={chain.child_user.avatar_url}/>
                                            <div>
                                                <div className={classes.str1}>{chain.child_user.first_name} {chain.child_user.last_name}</div>
                                                <div className={classes.str2}>{chain.child_user.position}</div>
                                                <div className={classes.str2}>{chain.child_user.company_name}</div>
                                            </div>
                                        </div>
                                        <ArrowForwardIosIcon className={classes.icon}/>
                                    </div>
                                    </Link>
                                </div>
                            }
                        </div>
                        
                    :
                        <div></div>
                    }
                </div>
            </div>
            

            
        </div>
    )
}

export default withCookies(Subticket)
