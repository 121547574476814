import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { withCookies } from 'react-cookie'

const apiUrl = "https://www.axelnode-dashboard.com/";
const token = localStorage.localJWT

export const fetchAsyncLogin = createAsyncThunk('login/post', async (auth) => {
    const res = await axios.post(`${apiUrl}api/v1/auth_token/login/`, auth, {
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return res.data;
} 
)

export const fetchAsyncUserGet = createAsyncThunk("user/get", async (token) => {
    const res = await axios.get(`${apiUrl}api/v1/users/user_info/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return res.data;
});

const loginSlice = createSlice({
    name: 'login',
    initialState: {
        authen: {
            email: '',
            password: '',
        },
        error: '',
        user: {
            first_name: "",
            last_name: "",
            avatar_url: null,
            has_access: false,
            id: 0,
            position: "",
            company_name: "",
            card: {},
            is_active: false,
            email: "", 
            date_joined: "",
            groups: [],
        }

    },
    reducers: {
        editEmail(state, action) {
            state.authen.email = action.payload;
        },
        editPassword(state, action) {
            state.authen.password = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncLogin.fulfilled, (state, action) => {
            state.error = ''
        });
        builder.addCase(fetchAsyncLogin.rejected, (state, action) => {
            state.error = action.error.message;
        });
        builder.addCase(fetchAsyncUserGet.fulfilled, (state, action) => {
            state.authen = action.payload;
        })
    }
})

export const {editEmail, editPassword } = loginSlice.actions;
export const selectAuthen = (state) => state.login.authen;
export const selectError = (state) => state.login.error;
export const selectUser = (state) => state.login.user;


export default loginSlice.reducer;


