import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const apiUrl = "https://www.axelnode-dashboard.com/";

export const fetchAsyncUserProGet = createAsyncThunk("user_pro/get", async (token) => {
    const res = await axios.get(`${apiUrl}api/v1/users_profiles/me/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return res.data;
});


export const fetchAsyncUserProPut = createAsyncThunk('user_pro/put', async (data) => {
    const res = await axios.put(`${apiUrl}api/v1/users_profiles/${data.user_pro.id}/`, data.user_pro, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)

export const fetchAsyncEducationPost = createAsyncThunk('education/post', async (data) => {
    const createData = new FormData();
    createData.append('institution', data['edu'].institution)
    createData.append('degree', data['edu'].degree)
    createData.append('field_of_study', data['edu'].field_of_study)
    createData.append('start_date', data['edu'].start_date)
    createData.append('end_date', data['edu'].end_date)
    const res = await axios.post(`${apiUrl}api/v1/users_profiles/${data['user_pro'].id}/educations/`, createData, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)

export const fetchAsyncEducationGet = createAsyncThunk('education/get', async (data) => {
    const res = await axios.get(`${apiUrl}api/v1/users_profiles/${data['user_pro'].id}/educations/${data['edu'].id}`, {
        headers: {
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)

export const fetchAsyncEducationPut = createAsyncThunk('education/put', async (data) => {
    const res = await axios.put(`${apiUrl}api/v1/users_profiles/${data['user_pro'].id}/educations/${data['edu'].id}`, data['edu'], {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)

export const fetchAsyncEducationDelete = createAsyncThunk('education/delete', async (data) => {
    await axios.delete(`${apiUrl}api/v1/users_profiles/${data['user_pro'].id}/educations/${data['edu'].id}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
} 
)

export const fetchAsyncExperiencePost = createAsyncThunk('experience/post', async (data) => {
    const createData = new FormData();
    createData.append('position', data['exp'].position)
    createData.append('ext_company', data['exp'].ext_company)
    createData.append('start_date', data['exp'].start_date)
    createData.append('end_date', data['exp'].end_date)
    createData.append('is_current', data['exp'].is_current)
    const res = await axios.post(`${apiUrl}api/v1/users_profiles/${data['user_pro'].id}/experiences/`, createData, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)

export const fetchAsyncExperienceGet = createAsyncThunk('experience/get', async (data) => {
    const res = await axios.get(`${apiUrl}api/v1/users_profiles/${data['user_pro'].id}/experiences/${data['exp'].id}`, {
        headers: {
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)

export const fetchAsyncExperiencePut = createAsyncThunk('experience/put', async (data) => {
    const res = await axios.put(`${apiUrl}api/v1/users_profiles/${data['user_pro'].id}/experiences/${data['exp'].id}`, data['exp'], {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)

export const fetchAsyncExperienceDelete = createAsyncThunk('experience/delete', async (data) => {
    await axios.delete(`${apiUrl}api/v1/users_profiles/${data['user_pro'].id}/experiences/${data['exp'].id}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
} 
)

export const fetchAsyncSkillPost = createAsyncThunk('skill/post', async (data) => {
    const createData = new FormData();
    createData.append('skill', data['skill'].name)
    const res = await axios.post(`${apiUrl}api/v1/users_profiles/${data['user_pro'].id}/skills/`, createData, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)

export const fetchAsyncSkillDelete = createAsyncThunk('skill/delete', async (data) => {
    await axios.delete(`${apiUrl}api/v1/users_profiles/${data['user_pro'].id}/skills/${data['skill'].id}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
} 
)

export const fetchAsyncLanguageGet = createAsyncThunk("language/get", async (token) => {
    const res = await axios.get(`${apiUrl}api/v1/data_store/languages/`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
    return res.data;
});

export const fetchAsyncLanguagePost = createAsyncThunk('language/post', async (data) => {
    const createData = new FormData();
    createData.append('language', parseInt(data['lang'].name))
    const res = await axios.post(`${apiUrl}api/v1/users_profiles/${data['user_pro'].id}/languages/`, createData, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
    return res.data;
} 
)

export const fetchAsyncLanguageDelete = createAsyncThunk('language/delete', async (data) => {
    await axios.delete(`${apiUrl}api/v1/users_profiles/${data['user_pro'].id}/languages/${data['lang'].id}`, {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${data.token}`,
        },
    });
} 
)

export const fetchAsyncCardGet = createAsyncThunk("card/get", async (user_id) => {
    const res = await axios.get(`${apiUrl}api/v1/cards/"${user_id}/`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return res.data;
});


const profileSlice = createSlice({
    name: 'profile',
    initialState: {
        user_pro: {
            id: 0,
            email: "",
            first_name: "",
            last_name: "",
            birth_date: null,
            avatar_url: null,
            position: "",
            company_name: "",
            gender: "NA",
            card: {'number_of_points': 0},
            skills: [],
            experiences: [{"id": 0, "position": "", "ext_company": "", "start_date": "", "end_date": ""}, ],
            educations: [{"id": 0, "institution": "", "degree": "", "field_of_study": "" ,"start_date": "", "end_date": ""}, ],
            languages: [],
            location:"", 
            professions: [],
            fb_link: "",
            ln_link: "",
            resume_link: "",
            cv_link: "",
            profile_visibility: "nobody",
            first_node_count: 0,
            second_node_count: 0,
            date_joined: ""
        },
        edu: {
            id: '',
            institution: '',
            degree: '',
            field_of_study: '',
            start_date: '',
            end_date: ''
        },
        exp: {
            id: '',
            position: '',
            ext_company: '',
            start_date: '',
            end_date: '',
            is_current: false,
        },
        skill: {
            id: '',
            name: '',
        }, 
        language_list: {
            count: '',
            next: null,
            previous: null,
            results: [],
        },
        language: {
            id: '',
            name: '',
        }, 
    },
    reducers: {
        editFirst(state, action) {
            state.user_pro.first_name = action.payload;
        },
        editLast(state, action) {
            state.user_pro.last_name = action.payload;
        },
        editEmail(state, action) {
            state.user_pro.email = action.payload;
        },
        editBirth(state, action) {
            state.user_pro.birth_date = action.payload;
        },
        editGender(state, action) {
            state.user_pro.gender = action.payload;
        },
        editLocation(state, action) {
            state.user_pro.location = action.payload;
        },
        editProfessions(state, action) {
            state.user_pro.professions = action.payload;
        },
        editFB(state, action) {
            state.user_pro.fb_link = action.payload;
        },
        editLN(state, action) {
            state.user_pro.ln_link = action.payload;
        },
        editResume(state, action) {
            state.user_pro.resume_link = action.payload;
        },
        editCV(state, action) {
            state.user_pro.cv_link = action.payload;
        },
        editProfile_visibility(state, action) {
            state.user_pro.profile_visibility = action.payload;
        },
        editEdu_id(state, action) {
            state.edu.id = action.payload;
        },
        editEdu_institution(state, action) {
            state.edu.institution = action.payload;
        },
        editEdu_dgree(state, action) {
            state.edu.degree = action.payload;
        },
        editEdu_field(state, action) {
            state.edu.field_of_study = action.payload;
        },
        editEdu_start(state, action) {
            state.edu.start_date = action.payload;
        },
        editEdu_end(state, action) {
            state.edu.end_date = action.payload;
        },
        editExp_id(state, action) {
            state.exp.id = action.payload;
        },
        editExp_position(state, action) {
            state.exp.position = action.payload;
        },
        editExp_company(state, action) {
            state.exp.ext_company = action.payload;
        },
        editExp_start(state, action) {
            state.exp.start_date = action.payload;
        },
        editExp_end(state, action) {
            state.exp.end_date = action.payload;
        },
        editExp_current(state, action) {
            state.exp.is_current = action.payload;
        },
        editSkill_id(state, action) {
            state.skill.id = action.payload;
        },
        editSkill_name(state, action) {
            state.skill.name = action.payload;
        },
        editLang_id(state, action) {
            state.language.id = action.payload;
        },
        editLang_name(state, action) {
            state.language.name = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAsyncUserProGet.fulfilled, (state, action) => {
          state.user_pro = action.payload;
        });
        builder.addCase(fetchAsyncUserProPut.fulfilled, (state, action) => {
            // window.location.href = '/profile';
        })
        builder.addCase(fetchAsyncEducationPost.fulfilled, (state, action) => {
            window.location.href = '/profile_edit';
        })
        builder.addCase(fetchAsyncEducationGet.fulfilled, (state, action) => {
            state.edu = action.payload;
        });
        builder.addCase(fetchAsyncEducationPut.fulfilled, (state, action) => {
            window.location.href = '/profile_edit';
        });
        builder.addCase(fetchAsyncEducationDelete.fulfilled, (state, action) => {
            window.location.href = '/profile_edit';
        });
        builder.addCase(fetchAsyncExperiencePost.fulfilled, (state, action) => {
            window.location.href = '/profile_edit';
        })
        builder.addCase(fetchAsyncExperienceGet.fulfilled, (state, action) => {
            state.exp = action.payload;
        });
        builder.addCase(fetchAsyncExperiencePut.fulfilled, (state, action) => {
            window.location.href = '/profile_edit';
        });
        builder.addCase(fetchAsyncExperienceDelete.fulfilled, (state, action) => {
            window.location.href = '/profile_edit';
        });
        builder.addCase(fetchAsyncLanguageGet.fulfilled, (state, action) => {
            state.language_list = action.payload;
        });
        // builder.addCase(fetchAsyncSkillPost.fulfilled, (state, action) => {
        //     window.location.href = '/profile_edit';
        // });
        // builder.addCase(fetchAsyncSkillDelete.fulfilled, (state, action) => {
        //     window.location.href = '/profile_edit';
        // });
        // builder.addCase(fetchAsyncLanguagePost.fulfilled, (state, action) => {
        //     window.location.href = '/profile_edit';
        // });
        // builder.addCase(fetchAsyncLanguageDelete.fulfilled, (state, action) => {
        //     window.location.href = '/profile_edit';
        // });
    },
})

export const { editFirst, editLast, editEmail, editBirth, editGender, editLocation, editProfessions, 
               editFB, editLN, editResume, editCV, editProfile_visibility, editEdu_institution, editEdu_dgree, 
               editEdu_field, editEdu_start, editEdu_end, editEdu_id, editExp_position, editExp_company,
               editExp_start, editExp_end, editExp_id, editExp_current, editSkill_id, editSkill_name,
               editLang_id, editLang_name } = profileSlice.actions;
export const selectProfile = (state) => state.profile.user_pro;
export const selectEducation = (state) => state.profile.edu;
export const selectExperience = (state) => state.profile.exp;
export const selectSkill = (state) => state.profile.skill;
export const selectLang = (state) => state.profile.language;
export const selectAvatar = (state) => state.profile.avatar;
export const selectLang_list = (state) => state.profile.language_list;

export default profileSlice.reducer;


