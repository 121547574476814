import React, { useEffect } from 'react';
import { withCookies } from "react-cookie";
import classes from './Notification.module.css';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import {
    editTicket_push, 
    editTicket_email, 
    editAxp_push, 
    editAxp_email, 
    editReward_push, 
    editReward_email,
    fetchAsyncNotificationGet,
    fetchAsyncNotificationPut,
    selectNotification,
} from "./notificationSlice";

import {
    selectProfile,
} from "../profile/profile_editSlice";


const Notification = (props) => {
    const dispatch = useDispatch();
    const token = props.cookies.get("current-token");
    const user_id = props.cookies.get("user_id");
    const notification = useSelector(selectNotification);
    const user = useSelector(selectProfile);


    useEffect(() => {
        dispatch(fetchAsyncNotificationGet({'user_id': user_id, 'token': token}))
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchAsyncNotificationPut({'user_id': user_id, 'note': notification, 'token': token}))
    }, [notification]);

    



    return (
        <div>
            <div className={classes.head}>
                <Link to='/setting/'>
                    <button className={classes.left}><ArrowBackIosIcon /></button>
                </Link>
                <span className={classes.center}>Notification settings</span>
            </div>
            <div>
                <div className={classes.box1}>
                    <span className={classes.srt1}>Ticket actions</span>
                </div>

                <div className={classes.box2}>
                    <span className={classes.srt2}>Email</span>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row className={classes.form}>
                            <FormControlLabel
                            value={notification.ticket_actions_email}
                            control={<Switch checked={notification.ticket_actions_email}  color="primary" />}
                            onChange={() => dispatch(editTicket_email(!notification.ticket_actions_email))}
                            disabled
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className={classes.box2}>
                    <span className={classes.srt2}>Push notifications</span>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row className={classes.form}>
                            <FormControlLabel
                            value={notification.ticket_actions_push}
                            control={<Switch checked={notification.ticket_actions_push}  color="primary" />}
                            onChange={() => dispatch(editTicket_push(!notification.ticket_actions_push))}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <hr className={classes.length}/>
            </div>

            <div>
                <div className={classes.box1}>
                    <span className={classes.srt1}>AXP updates</span>
                </div>
                <div className={classes.box2}>
                    <span className={classes.srt2}>Email</span>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row className={classes.form}>
                            <FormControlLabel
                            value={notification.axp_updates_email}
                            control={<Switch checked={notification.axp_updates_email} color="primary" />}
                            onChange={() => dispatch(editAxp_email(!notification.axp_updates_email))}
                            disabled
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className={classes.box2}>
                    <span className={classes.srt2}>Push notifications</span>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row className={classes.form}>
                            <FormControlLabel
                            value={notification.axp_updates_push}
                            control={<Switch checked={notification.axp_updates_push} color="primary" />}
                            onChange={() => dispatch(editAxp_push(!notification.axp_updates_push))}
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <hr className={classes.length}/>
            </div>

            <div>
                <div className={classes.box1}>
                    <span className={classes.srt1}>Reward updates</span>
                </div>

                <div className={classes.box2}>
                    <span className={classes.srt2}>Email</span>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row className={classes.form}>
                            <FormControlLabel
                            value={notification.reward_updates_email}
                            control={<Switch checked={notification.reward_updates_email} color="primary" />}
                            onChange={() => dispatch(editReward_email(!notification.reward_updates_email))}
                            disabled
                            />
                        </FormGroup>
                    </FormControl>
                </div>
                <div className={classes.box2}>
                    <span className={classes.srt2}>Push notifications</span>
                    <FormControl component="fieldset">
                        <FormGroup aria-label="position" row className={classes.form}>
                            <FormControlLabel
                            value={notification.reward_updates_push}
                            control={<Switch checked={notification.reward_updates_push} color="primary" />}
                            onChange={() => dispatch(editReward_push(!notification.reward_updates_push))}
                            disabled
                            />
                        </FormGroup>
                    </FormControl>
                </div>
            </div>   
        </div>
    )
}

export default withCookies(Notification)
